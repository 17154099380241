import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  SimpleBlackText,
} from '../../../../../../../shared/styles';
import { SettingsWrapper, TextMgB } from '../styled';
import { CreatorForm, PlanTypes } from '../../../../../../../shared/types';
import { CommonSwitch } from '../../../../../../../shared/components/FormElements/CommonSwitch/CommonSwitch';
import { ListItemContainer } from '../../../../../../../shared/components/FormElements/CommonSwitch/ListItemContainer';
import { useTypedSelectorCreator } from '../../../../../../../shared/hooks/useTypedSelector';
import { isBasicPlanType } from '../../../../../utils/plans';

export const EmailNotificationsAndBranding: React.FC = () => {
  const { t } = useTranslation('settings');
  const planType = useTypedSelectorCreator(
    (state) => state.creator?.currentUser?.currentSubscription?.planName,
  );

  const {
    values, handleChange,
  } = useFormikContext<CreatorForm>();

  return (
    <>
      <SettingsWrapper>
        <TextMgB>
          <SimpleBlackText>{t('emailNotifications')}</SimpleBlackText>
        </TextMgB>
        <Grid container>
          {/* <Grid item sm={10}>
            <CommonSwitch
              isChecked={values.downloadsCart}
              handleChange={handleChange}
              name="downloadsCart"
              label={t('switches.downloadCart')}
              helpText={t('helpTips.cart')}
              positionHelpText="top"
              spaceBetween
              withoutMargins
              littleText
            />
          </Grid>
          <Grid item sm={10}>
            <CommonSwitch
              isChecked={values.downloadsItem}
              handleChange={handleChange}
              name="downloadsItem"
              label={t('switches.downloadSingleDelivrable')}
              spaceBetween
              withoutMargins
              littleText
            />
          </Grid> */}
          <Grid item sm={10}>
            <CommonSwitch
              isChecked={values.createsFavourites}
              handleChange={handleChange}
              name="createsFavourites"
              label={t('switches.createNewFavouritesList')}
              spaceBetween
              withoutMargins
              littleText
            />
          </Grid>
          <Grid item sm={10}>
            <CommonSwitch
              isChecked={values.leavesComment}
              handleChange={handleChange}
              name="leavesComment"
              label={t('switches.leaveComment')}
              spaceBetween
              withoutMargins
              littleText
            />
          </Grid>
          {/* <Grid item sm={10}>
            <CommonSwitch
              isChecked={values.purchasesItem}
              handleChange={handleChange}
              name="purchasesItem"
              label={t('switches.makePurchase')}
              spaceBetween
              withoutMargins
              littleText
            />
          </Grid>
          <Grid item sm={10}>
            <CommonSwitch
              isChecked={values.purchasesIdea}
              handleChange={handleChange}
              name="purchasesIdea"
              label={t('switches.purchaseIdea')}
              spaceBetween
              withoutMargins
              littleText
            />
          </Grid>
          <Grid item sm={10}>
            <CommonSwitch
              isChecked={values.solvedIdeaReminder}
              handleChange={handleChange}
              name="solvedIdeaReminder"
              label={t('switches.reminderIdea')}
              helpText={t('helpTips.reminder')}
              positionHelpText="top"
              spaceBetween
              withoutMargins
              littleText
            />
          </Grid>
          <Grid item sm={10}>
            <CommonSwitch
              isChecked={values.inviteFailed}
              handleChange={handleChange}
              name="inviteFailed"
              label={t('switches.emailInvite')}
              spaceBetween
              withoutMargins
              littleText
            />
          </Grid>
          <Grid item sm={10}>
            <CommonSwitch
              isChecked={values.storageFull}
              handleChange={handleChange}
              name="storageFull"
              label={t('switches.accountStorageIsFull')}
              spaceBetween
              withoutMargins
              littleText
            />
          </Grid>
          <Grid item sm={10}>
            <CommonSwitch
              isChecked={values.activeGalleryAboutExpiration}
              handleChange={handleChange}
              name="activeGalleryAboutExpiration"
              label={t('switches.expireAlert')}
              spaceBetween
              withoutMargins
              littleText
            />
          </Grid> */}
          {/* //! hide for now */}
          {/* <Grid item sm={10}>
            <CommonSwitch
              isChecked={values.newFeatures}
              handleChange={handleChange}
              name="newFeatures"
              label={t('switches.newFeature')}
              spaceBetween
              withoutMargins
              littleText
            />
          </Grid> */}
          {/* //! hide for now */}
          {/* <Grid item sm={10}>
            <ListItemContainer styles={{ width: '100%' }}>
              <CommonSwitch
                isChecked={values.salesAndActivityReport}
                handleChange={handleChange}
                name="salesAndActivityReport"
                label={t('switches.monthlySales')}
                spaceBetween
                withoutMargins
                disabled={!isBasicPlanType(planType as PlanTypes)}
                littleText
                extraText={t('comingSoon')}
              />
            </ListItemContainer>
          </Grid> */}
          {/* //! hide for now */}
          {/* <Grid item sm={10}>
            <ListItemContainer styles={{ width: '100%' }}>
              <CommonSwitch
                isChecked={values.receivesReward}
                handleChange={handleChange}
                name="receivesReward"
                label={t('switches.receiveReferralReward')}
                spaceBetween
                withoutMargins
                disabled={!isBasicPlanType(planType)}
                littleText
              />
            </ListItemContainer>
          </Grid> */}
        </Grid>
      </SettingsWrapper>
      {/* //! hide for now */}
      {/* <SettingsWrapper>
        <SimpleBlackText>{t('branding')}</SimpleBlackText>
        <Grid container>
          <Grid item sm={10}>
            <ListItemContainer styles={{ width: '100%' }}>
              <CommonSwitch
                isChecked={!values.branding}
                handleChange={handleChange}
                name="branding"
                label={t('switches.removeBranding')}
                spaceBetween
                disabled={!isBasicPlanType(planType as PlanTypes)}
                littleText
              />
            </ListItemContainer>
          </Grid>
        </Grid>
      </SettingsWrapper> */}
    </>
  );
};
