/* eslint-disable implicit-arrow-linebreak */
import { UploadingItem } from '../../modules/Creator/types/gallery';
import { MobileOS } from '../types';
import {
  ClientGalleryFile, CreatorGalleryFile, GalleryFileBase, GalleryFileBaseWithUrls, GalleryIdea,
} from '../types/commonGallery';

export const isArrayIncludeValue = <A>(arr: A[], value: A): boolean =>
  (arr ? arr.includes(value) : false);

export const getCustomFileObj = (url: string): GalleryFileBaseWithUrls => {
  const type = 'image';

  return ({
    type,
    url,
    name: '',
    id: url,
  });
};

export const isVideo = (file: GalleryFileBaseWithUrls | File | null | UploadingItem): boolean => (file ? (file.type?.includes('video') || (file as any)?.mimeType?.includes('video')) : false);

export const isImage = (file: GalleryFileBaseWithUrls | File | null | UploadingItem): boolean => (file ? (file?.type?.includes('image') || (file as any)?.mimeType?.includes('image')) : false);

export const isDocument = (file: GalleryFileBaseWithUrls | File | null): boolean =>
  (file ? !isImage(file) && !isVideo(file) : false);

export const isIdea = (
  file?: ClientGalleryFile | CreatorGalleryFile | GalleryIdea | null,
): boolean =>
  (file ? (!!(file as GalleryIdea).cover) : false);

export const getURLByFile = (file: File): string => URL.createObjectURL(file);

export const isFirstItemInArr = (index: number): boolean => index === 0;

export const isLastItemInArr = <T>(arr: T[], index: number): boolean => index === arr.length - 1;

export const isArrayEmpty = <A>(arr?: A[]): boolean => (arr ? arr?.length <= 0 : true);

export const getPrice = (value: string | number) => Number(value) / 100;

export const getMobileOS = (): MobileOS => {
  const ua = navigator.userAgent;
  if (/android/i.test(ua)) {
    return 'Android';
  } if (/iPad|iPhone|iPod/.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
    return 'IOS';
  }
  return 'Others';
  // const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // // Windows Phone must come first because its UA also contains "Android"
  // if (/windows phone/i.test(userAgent)) {
  //   return 'Windows Phone';
  // }

  // if (/android/i.test(userAgent)) {
  //   return 'Android';
  // }

  // // iOS detection from: http://stackoverflow.com/a/9039885/177710
  // if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
  //   return 'iOS';
  // }

  // return 'unknown';
};

export const sortByDate = <T extends object>(
  arr: T[],
  sortedBy: 'asc' | 'desc',
  fieldName: keyof T,
) => arr.sort((a, b) => (sortedBy === 'asc'
    ? new Date(a[fieldName] as Date).getTime()
          - new Date(b[fieldName] as Date).getTime()
    : new Date(b[fieldName] as Date).getTime()
          - new Date(a[fieldName] as Date).getTime()));
