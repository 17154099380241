import React from 'react';
import { useTranslation } from 'react-i18next';
import { LandingNumberedLi } from '../LandingNumberedLi';
import { TermsAndPrivacyNumberedList } from '../TermsAndPrivacyNumberedList';
import {
  TermsAndPrivacyInsideListWrapper,
  TermsAndPrivacyListItem,
} from '../styled';

export const RefundsAndCancelPoints: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <TermsAndPrivacyNumberedList
      startWith={10}
      listTitle={t('terms.refundsCancel')}
      pointsNameForTrans="refundsCancelPoints"
    >
      <LandingNumberedLi>
        No Refunds Policy
        <TermsAndPrivacyInsideListWrapper style={{ paddingLeft: 0 }}>
          <TermsAndPrivacyListItem noPaddingLeft listMarker="none">
            All payments made by a Client via the Platform are
            {' '}
            <span className="bold">final</span>
            {' '}
            and
            <span className="bold">non-refundable</span>
            . Delivrable does not
            process, facilitate, or guarantee refunds for digital content.
            Clients acknowledge that they are not entitled to a refund unless
            explicitly agreed upon by the Member.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        Member Responsibility for Refunds
        <TermsAndPrivacyInsideListWrapper style={{ paddingLeft: 0 }}>
          <TermsAndPrivacyListItem noPaddingLeft listMarker="none">
            The cancellation, exchange, or refund of any digital content ordered
            through the Platform is strictly a
            {' '}
            <span className="bold">
              private arrangement between the Client and the Member
            </span>
            . The Member is solely responsible for determining refund
            eligibility and
            {' '}
            <span className="bold">
              processing refunds via their Stripe Express account
            </span>
            . Delivrable does
            {' '}
            <span className="bold">not</span>
            {' '}
            manage or
            mediate refund requests.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        Deferred Payment Service Fee & Refund Liability
        <TermsAndPrivacyInsideListWrapper style={{ paddingLeft: 0 }}>
          <TermsAndPrivacyListItem noPaddingLeft listMarker="none">
            Should a Member agree to a refund of the Project Fees or sale of
            Output Data, the Member acknowledges that:
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyInsideListWrapper>
            <TermsAndPrivacyListItem listMarker="disc">
              <span className="bold">
                The Deferred Payment Service Fee (15%) is non-refundable
              </span>
              {' '}
              and will not be returned to the Member.
            </TermsAndPrivacyListItem>
            <TermsAndPrivacyListItem listMarker="disc">
              The Member assumes
              {' '}
              <span className="bold">all financial liability</span>
              {' '}
              for refunds,
              chargebacks, and any associated payment processing costs.
            </TermsAndPrivacyListItem>
            <TermsAndPrivacyListItem listMarker="disc">
              Stripe’s chargeback fees, if applicable, are the
              {' '}
              <span className="bold">responsibility of the Member</span>
              {' '}
              and
              will not be covered by Delivrable.
            </TermsAndPrivacyListItem>
          </TermsAndPrivacyInsideListWrapper>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        Private Refund Arrangements
        <TermsAndPrivacyInsideListWrapper style={{ paddingLeft: 0 }}>
          <TermsAndPrivacyListItem noPaddingLeft listMarker="none">
            If a refund is issued, it is the
            {' '}
            <span className="bold">sole responsibility</span>
            {' '}
            of the Member and
            Client to arrange this privately. Delivrable will not intervene or
            facilitate this process.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        Disputes Between Clients and Members
        <TermsAndPrivacyInsideListWrapper style={{ paddingLeft: 0 }}>
          <TermsAndPrivacyListItem noPaddingLeft listMarker="none">
            Delivrable does
            {' '}
            <span className="bold">not</span>
            {' '}
            provide dispute
            resolution services. We encourage both Clients and Members to
            resolve disputes
            {' '}
            <span className="bold">privately and in good faith</span>
            {' '}
            using
            external communication methods. In cases where a resolution cannot
            be reached, the parties may seek legal recourse independently.
            {' '}
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        Compliance with Consumer Protection Laws
        <TermsAndPrivacyInsideListWrapper style={{ paddingLeft: 0 }}>
          <TermsAndPrivacyListItem noPaddingLeft listMarker="none">
            Nothing in this section limits a Client’s rights under
            {' '}
            <span className="bold">applicable consumer protection laws</span>
            {' '}
            where refunds may be legally required. Members are responsible for
            ensuring compliance with any relevant legal obligations in their
            country of operation.
          </TermsAndPrivacyListItem>
          {' '}
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        Survival of this Clause
        <TermsAndPrivacyInsideListWrapper style={{ paddingLeft: 0 }}>
          <TermsAndPrivacyListItem noPaddingLeft listMarker="none">
            This Refunds and Cancellation Policy shall
            {' '}
            <span className="bold">survive the termination or expiry</span>
            {' '}
            of
            these Terms.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
    </TermsAndPrivacyNumberedList>
  );
};
