import React from 'react';
import { useTranslation } from 'react-i18next';
import { LandingNumberedLi } from '../LandingNumberedLi';
import {
  TermsAndPrivacyInsideListWrapper,
  TermsAndPrivacyListItem,
} from '../styled';
import { TermsAndPrivacyNumberedList } from '../TermsAndPrivacyNumberedList';

export const TerminationPoints: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <TermsAndPrivacyNumberedList
      startWith={18}
      listTitle={t('terms.termination')}
      pointsNameForTrans="terminationPoints"
    >
      <LandingNumberedLi>
        Member-Initiated Termination
        <TermsAndPrivacyInsideListWrapper style={{ paddingLeft: 0 }}>
          <TermsAndPrivacyListItem noPaddingLeft listMarker="none">
            You may terminate your Account and these Terms at any time using the
            ‘Cancel Account’ functionality (or similar) in your Account
            settings.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        Termination for Convenience by Delivrable
        <TermsAndPrivacyInsideListWrapper style={{ paddingLeft: 0 }}>
          <TermsAndPrivacyListItem noPaddingLeft listMarker="none">
            We may terminate these Terms at any time by providing 30 days’
            written notice to you (Termination for Convenience).
            {' '}
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        Immediate Termination for Breach
        <TermsAndPrivacyInsideListWrapper style={{ paddingLeft: 0 }}>
          <TermsAndPrivacyListItem noPaddingLeft listMarker="none">
            These Terms may be terminated immediately upon written notice by a
            Party (Non-Defaulting Party) if:
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyInsideListWrapper>
            <TermsAndPrivacyListItem listMarker="disc">
              the other Party (Defaulting Party) breaches a material term of
              these Terms and does not remedy that breach within 10 Business
              Days of receiving notice from the Non-Defaulting Party;
            </TermsAndPrivacyListItem>
            <TermsAndPrivacyListItem listMarker="disc">
              the Defaulting Party commits repeated or persistent non-material
              breaches that, when taken together, constitute a material breach;
            </TermsAndPrivacyListItem>
            <TermsAndPrivacyListItem listMarker="disc">
              the Defaulting Party is unable to pay its debts as they fall due,
              enters liquidation, or becomes insolvent.
            </TermsAndPrivacyListItem>
          </TermsAndPrivacyInsideListWrapper>
          <TermsAndPrivacyListItem noPaddingLeft listMarker="none">
            If termination occurs due to breach, the Defaulting Party is not
            entitled to any refunds, including Membership Fees, Deferred Payment
            Service Fees, or other amounts paid in advance.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        Suspension of Account for Suspected Breach
        <TermsAndPrivacyInsideListWrapper style={{ paddingLeft: 0 }}>
          <TermsAndPrivacyListItem noPaddingLeft listMarker="none">
            If we suspect that you are in breach of these Terms, we may suspend
            your Account while we investigate the suspected breach. If we
            determine, in our sole discretion, that a breach has occurred, we
            may:
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyInsideListWrapper>
            <TermsAndPrivacyListItem listMarker="disc">
              terminate your Account immediately; or
            </TermsAndPrivacyListItem>
            <TermsAndPrivacyListItem listMarker="disc">
              reinstate your Account upon resolution of the issue.
            </TermsAndPrivacyListItem>
          </TermsAndPrivacyInsideListWrapper>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        Effects of Termination
        <TermsAndPrivacyInsideListWrapper style={{ paddingLeft: 0 }}>
          <TermsAndPrivacyListItem noPaddingLeft listMarker="none">
            Upon expiry or termination of these Terms:
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyInsideListWrapper>
            <TermsAndPrivacyListItem listMarker="disc">
              we will remove your access to the Platform;
            </TermsAndPrivacyListItem>
            <TermsAndPrivacyListItem listMarker="disc">
              we will immediately cease providing Delivrable Services;
            </TermsAndPrivacyListItem>
            <TermsAndPrivacyListItem listMarker="disc">
              if you are a Member, you acknowledge that Membership Fees are
              non-refundable, and any outstanding amounts owed to Delivrable
              must be settled before termination is complete;
            </TermsAndPrivacyListItem>
            <TermsAndPrivacyListItem listMarker="disc">
              if you are a Member, we will cancel any existing confirmed
              Projects, and you will lose any Project Fees or other amounts paid
              in advance for goods/services not yet received;
            </TermsAndPrivacyListItem>
            <TermsAndPrivacyListItem listMarker="disc">
              if you are a Client, we will cancel any existing confirmed
              Projects, and if you have paid in advance, we will refund you for
              any payments made in advance for services not yet received, less
              our Deferred Payment Service Fee;
            </TermsAndPrivacyListItem>
            <TermsAndPrivacyListItem listMarker="disc">
              if termination is due to a breach, we reserve the right to charge
              you for reasonable additional costs directly arising from such
              termination, including legal fees, debt collection fees, and
              mercantile agent fees;
            </TermsAndPrivacyListItem>
            <TermsAndPrivacyListItem listMarker="disc">
              Delivrable is not responsible for refunding payments made by
              Clients to Members. Any refund requests must be resolved between
              the Client and the Member directly.
            </TermsAndPrivacyListItem>
          </TermsAndPrivacyInsideListWrapper>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        Data Retention & Account Deletion
        <TermsAndPrivacyInsideListWrapper style={{ paddingLeft: 0 }}>
          <TermsAndPrivacyListItem noPaddingLeft listMarker="none">
            Upon termination of your Account, your data, including gallery
            files, transaction records, and stored content, may be permanently
            deleted immediately. Delivrable is not responsible for retaining or
            restoring lost data after termination.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        Survival of Terms
        <TermsAndPrivacyInsideListWrapper style={{ paddingLeft: 0 }}>
          <TermsAndPrivacyListItem noPaddingLeft listMarker="none">
            The provisions of these Terms that by their nature should survive
            termination shall survive, including but not limited to Sections 9
            (Payments to Members), 10 (Refunds & Cancellations), 17 (Limitations
            of Liability), and 19 (Intellectual Property).
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
    </TermsAndPrivacyNumberedList>
  );
};
