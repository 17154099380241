import styled from '@emotion/styled';
import * as variables from '../../../../../../constants/styles';
import { theme } from '../../../../../../shared/components/GlobalContainer/styled';

export const FilterButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  & > button {
    &:not(:first-of-type) {
      margin-left: 7px;
    }
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    justify-content: flex-start;

    & > button {
      &:not(:first-of-type) {
        margin-left: 0px;
      }
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
`;

export const LineGrey = styled.div`
  width: 100%;
  padding-bottom: 1px;
  background: #d7d7d7;
  margin: 28px 0;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    margin: 12px 0;
  }
`;

export const SectionTitleLarge = styled.div`
  font-size: 28px;
  line-height: 26px;
  color: ${variables.colorBlack};

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    display: none;
  }
`;

export const GallerySectionHeader = styled.div`
  margin-bottom: 30px;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    margin-bottom: 40px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    padding: 0px 16px;
    margin-bottom: 20px;
  }
`;

export const GallerySectionTitle = styled.div`
  @media (max-width: ${theme.breakpoints.values.md}px) {
    display: none;
  }
`;

export const GallerySectionFreeFiles = styled.div`
  display: flex;

  @media (max-width: ${theme.breakpoints.values.md}px) {
    display: none;
  }
`;

export const SectionTitleSelectWrapper = styled.div`
  @media (min-width: ${theme.breakpoints.values.md}px) {
    display: none;
  }
`;

export const FiltersAndFreeFilesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const SectionItemItemsList = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${theme.breakpoints.values.md}px) {
    padding: 0px 16px;
  }
`;

export const DownloadAllButtonElement = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.2s;

  & div, span, svg {
      transition: all 0.2s;
      color: ${variables.textGrey};
    }

  &:hover {
    & div, span, svg {
      transition: all 0.2s;
      color: ${variables.colorPink};
    }
  }

  &:active {
    & div, span, svg {
      transition: all 0.1s;
      color: ${variables.colorPink};
      font-size: 15.5px;
    }
  }
`;
