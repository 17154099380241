import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { TermsAndPrivacyListWrapper, TermsAndPrivacyListItem } from '../styled';
import * as urls from '../../../../../constants/urls/landingUrls';

export const OurDisclosuresPoints: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <TermsAndPrivacyListWrapper>
      <Trans t={t} i18nKey="terms.ourDisclosuresPoints">
        <TermsAndPrivacyListItem>
          If you are using the Platform as a Member, you also agree to our
          Membership Terms and Conditions available at
          <Link to={`/${urls.termsUrl}`}>www.delivrable.io/terms</Link>
          , and
          to the extent of any inconsistency, the Membership Terms and
          Conditions take priority;
        </TermsAndPrivacyListItem>
        <TermsAndPrivacyListItem>
          We may amend or terminate these Terms at any time, by providing
          written notice to you, including via the Platform;
        </TermsAndPrivacyListItem>
        <TermsAndPrivacyListItem>
          We will handle your personal information in accordance with our
          Privacy Policy, available at
          {' '}
          <Link to={`/${urls.privacyUrl}`}>www.delivrable.io/privacy</Link>
          ;
        </TermsAndPrivacyListItem>
        <TermsAndPrivacyListItem>
          If you are a Client, we make no guarantees relating to
          availability of the Platform to you. If you are a Member, our
          availability commitments and potential Liability to you are set
          out in our Membership Terms and Conditions;
        </TermsAndPrivacyListItem>
        <TermsAndPrivacyListItem>
          Our Liability is limited under these Terms. If you are a Member,
          our aggregate liability for any Liability arising from or in
          connection with these Terms will be excluded and limited as set
          out in the Membership Terms and Conditions. If you are a Client,
          our aggregate liability for any Liability arising from or in
          connection with these Terms will be limited to us resupplying the
          Delivrable Services to you or, in our sole discretion, to us
          paying you $1.00. We exclude any Liability for any aspect of the
          Client and Member interaction including Your Data, goods offered
          by the Member (Output Data), the description of the goods
          requested or offered, any advice provided, or supply and delivery
          of goods;
        </TermsAndPrivacyListItem>
        <TermsAndPrivacyListItem>
          Subject to any rights available to a Member in the Membership
          Terms and Conditions, we may terminate your access to our Platform
          at any time with notice;
        </TermsAndPrivacyListItem>
        <TermsAndPrivacyListItem>
          To the maximum extent permitted by law, the Project Fees you (a
          Client) pay to a Member are non-refundable except as to you
          otherwise agree privately off Platform. If you have a dispute with
          a Member, we will not get involved and you should take this up
          with the Member;
        </TermsAndPrivacyListItem>
        <TermsAndPrivacyListItem>
          We receive a fee from Members based on the Membership type of the
          Member;
        </TermsAndPrivacyListItem>
        <TermsAndPrivacyListItem>
          You, a Member, agree that we may set-off or deduct from any monies
          payable to you under these Terms, any amounts which are payable by
          you to us (whether under these Terms or otherwise).
        </TermsAndPrivacyListItem>
        <TermsAndPrivacyListItem>
          We may and may receive a benefit (which may include a referral fee
          or a commission) should you visit certain third-party websites via
          a link on the Platform or for featuring certain products or
          services on the Platform.
        </TermsAndPrivacyListItem>
      </Trans>
    </TermsAndPrivacyListWrapper>
  );
};
