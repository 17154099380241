import React from 'react';
import { useTranslation } from 'react-i18next';
import { LandingNumberedLi } from '../LandingNumberedLi';
import { TermsAndPrivacyNumberedList } from '../TermsAndPrivacyNumberedList';
import * as urls from '../../../../../constants/urls/landingUrls';

export const PrivacyPoints: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <TermsAndPrivacyNumberedList
      startWith={21}
      listTitle={t('terms.privacy')}
      pointsNameForTrans="privacyPoints"
    >
      <LandingNumberedLi>
        We respect your privacy and understand protecting your personal
        information is important. Our Privacy Policy (available on the
        Platform
        {' '}
        <a target="_blink" href={urls.privacyUrl}>www.delivrable.io/privacy</a>
        ) sets out how we
        will collect and handle your personal information.
      </LandingNumberedLi>
      <LandingNumberedLi>
        This Privacy Collection Notice describes how we collect and handle
        your personal information when you use our Platform and SaaS
        Solution or make an enquiry with. We collect personal information
        from you and from third parties, including our cookie and analytics
        providers, Google Analytics so that we can provide our Platform and
        SaaS Solution to you, respond to your enquiries and for related
        purposes set out in our Privacy Policy.
      </LandingNumberedLi>
      <LandingNumberedLi>
        We may disclose this personal information to third parties,
        including our web-hosting and server providers such as Amazon Web
        Services and any third parties engaged by us and acting on our
        behalf and as otherwise set out in our Privacy Policy.
      </LandingNumberedLi>
      <LandingNumberedLi>
        While we store personal information in Australia, where we disclose
        your personal information to the third parties listed above, these
        third parties may store, transfer or access personal information
        outside of Australia.
      </LandingNumberedLi>
      <LandingNumberedLi>
        If you do not provide your personal information to us, it may affect
        our ability to provide our Platform to you and your use of our SaaS
        Solution.
      </LandingNumberedLi>
      <LandingNumberedLi>
        Please see our Privacy Policy (linked above) for more information
        about how we collect, store, use and disclose your personal
        information, including details about overseas disclosure, access,
        correction, how you can make a privacy-related complaint and our
        complaint-handling process.
      </LandingNumberedLi>
      <LandingNumberedLi>
        If you have questions about our privacy practices, please contact us
        via the Platform.
      </LandingNumberedLi>
    </TermsAndPrivacyNumberedList>
  );
};
