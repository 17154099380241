import styled from '@emotion/styled';
import { theme } from '../../../../../shared/components/GlobalContainer/styled';
import { TextBlackLarge, TextBlackTwenty, TitleText } from '../../../../../shared/styles';
import * as variables from '../../../../../constants/styles';

export const CartSummaryContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 60px 80px;
  box-sizing: border-box;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    padding: 30px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    padding: 0;
  }
`;

export const CartSummaryTitle = styled(TitleText)`
  display: block;
  margin-bottom: 40px;
  font-weight: 600;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 24px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    font-weight: 500;
    font-size: 17px;
    margin-top: 10px;
  }
`;

export const CartSummarySectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 30px;
  box-sizing: border-box;
  margin-bottom: 30px;
  gap: 24px;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    padding-right: 0px;
    gap: 16px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    padding-right: 0px;
    margin-bottom: 0px;
  }
`;

export const CartSummarySectionWrapper = styled.div`
  padding-bottom: 14px;
  padding-top: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &:first-of-type {
    padding-top: 0px;
  }

  &:last-child {
    padding-bottom: 0px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    align-items: flex-start;
    border-bottom: 0.5px solid ${variables.colorBleak};

    &:last-child {
      border-bottom: none;
    }

    & div {
      font-size: 16px;
    }
  }
`;

export const CartSummaryFilesNumberPrice = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;

  @media (max-width: ${theme.breakpoints.values.md}px) {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    & > div {
      &:first-of-type {
        padding-bottom: 10px;
      }

      box-shadow: none;
    }
  }
`;

export const CartSummaryContainer = styled.div`
  width: 50%;
  max-width: 645px;
  box-sizing: border-box;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    width: 48%;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    width: 100%;

    & > div {
      box-shadow: none;
    }
  }
`;

export const SubtotalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
  padding-right: 30px;
  box-sizing: border-box;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    padding-right: 0px;
    margin-top: 28px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    margin-top: 40px;
  }
`;

export const CartCheckoutButtonWrapper = styled.div`
  margin-top: 39px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    flex-direction: column;
    margin-top: 30px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    & > button {
      width: 100%;
    }
  }
`;

export const CartCheckoutSectionText = styled(TextBlackLarge)`
  @media (max-width: ${theme.breakpoints.values.lg}px) {
    font-size: 14px;
    line-height: 140%;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    font-size: 16px;
    line-height: 140%;
  }
`;

export const CartCheckoutSectionTextLimit = styled(CartCheckoutSectionText)`
  & p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    padding: 0;
  }
`;

export const CartSummaryText = styled(TextBlackTwenty)`
  @media (max-width: ${theme.breakpoints.values.lg}px) {
    font-size: 14px;
    line-height: 140%;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    font-size: 16px;
  }
`;

export const CartSummaryLoaderWrapper = styled.div`
  display: flex;
  min-height: 24px;
  height: 24px;
  max-height: 24px;
  margin-left: 16px;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    margin-top: 10px;
    
    & div {
      font-size: 12px;
    }
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    & div {
      font-size: 10px;
    }
  }
`;

export const CartSummaryDownloadReady = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
