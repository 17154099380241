import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { BaseColors } from '../../../types';
import * as variables from '../../../../constants/styles';

const getMaxWidth = (size?: 'xs' | 'sm') => {
  switch (size) {
    case 'xs':
      return '24px';
    case 'sm':
      return '28px';
    default:
      return 'unset';
  }
};

export const PositionedLoader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: inherit;
  border-radius: inherit;
  width: 100%;
  height: 100%;
`;

const loadingSpinner = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const LoaderWrapper = styled.div<{ size?: 'xs' | 'sm', color?: BaseColors }>`
  display: flex;
  width: fit-content;
  height: fit-content;

  animation: ${loadingSpinner} 1.5s linear infinite;
  
  & .wrapper {
    height: 100vh;
    max-height: 600px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
  }

  & .message {
    text-align: center;
    margin-top: 0.25em;
  }

  & #spinner {
    width: 40px;
    max-width: ${({ size }) => (getMaxWidth(size))};

    & #sGD{
      & stop {
        stop-color: ${({ color }) => (color === 'pink' ? '#F180B2' : variables.colorGreyLight)};
      }
    } 

    & .path-solid {
      stroke: ${({ color }) => (color === 'pink' ? '#F180B2' : variables.colorGreyLight)};
    }

    &.path-gradient {
    }
  }
`;
