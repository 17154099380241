import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Stack } from '@mui/material';
import { CommonPriceInputReadOnly } from '../../../../../shared/components/CommonPriceInputReadOnly/CommonPriceInputReadOnly';
import {
  CartCheckoutSectionText,
  CartSummaryFilesNumberPrice,
  CartCheckoutSectionTextLimit,
} from './styled';
import {
  ClientGalleryFile, CurrencyTypes, GalleryIdea,
} from '../../../../../shared/types';
import { GradientColorText } from '../../../../../shared/styles';

type CartSummarySectionProps = {
  sectionId: string;
  sectionTitle: string;
  files: (ClientGalleryFile | GalleryIdea)[];
  freeFiles: (ClientGalleryFile | GalleryIdea)[];
};

export const CartSummarySection: React.FC<CartSummarySectionProps> = ({
  sectionId,
  sectionTitle,
  files,
  freeFiles,
}) => {
  const { t } = useTranslation('sales');
  const filesNumber = files?.length;
  const freeFilesNumber = freeFiles?.length;
  const filesTotalSum = useMemo(() => {
    let res = 0;
    files.forEach((item) => {
      res += Number(item.price) || 0;
    });
    return res;
  }, [files]);

  return (
    <Stack gap="8px" width="100%">
      <Box width="100%" key={sectionId} gap="8px" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <CartCheckoutSectionTextLimit>
          <p>{sectionTitle}</p>
        </CartCheckoutSectionTextLimit>
        <CartSummaryFilesNumberPrice>
          <CartCheckoutSectionText style={{ whiteSpace: 'pre' }}>
            <Trans t={t} i18nKey="files">
              {{ files: filesNumber }}
              files
            </Trans>
          </CartCheckoutSectionText>
          <CartCheckoutSectionText>
            <CommonPriceInputReadOnly currenyType={CurrencyTypes.USD} currencyLetters="USD" value={filesTotalSum || 0} />
          </CartCheckoutSectionText>
        </CartSummaryFilesNumberPrice>
      </Box>
      {freeFilesNumber > 0 && (
      <Box width="100%" key={`${sectionId}-free`} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <CartCheckoutSectionTextLimit>
          <p>{' '}</p>
        </CartCheckoutSectionTextLimit>
        <CartSummaryFilesNumberPrice>
          <CartCheckoutSectionText style={{ whiteSpace: 'pre' }}>
            <Trans t={t} i18nKey="files">
              {{ files: freeFilesNumber }}
              files
            </Trans>
          </CartCheckoutSectionText>
          <CartCheckoutSectionText fontWeight={600}>
            <GradientColorText fontWeight={600}>FREE</GradientColorText>
          </CartCheckoutSectionText>
        </CartSummaryFilesNumberPrice>
      </Box>
      )}
    </Stack>
  );
};
