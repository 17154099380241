import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorType } from '../../../../../shared/types';
import {
  fetchDeleteCreatorGallery,
  fetchGetCreatorGalleries,
} from './galleryCreatorThunk';
import { CreatorGalleryGet } from '../../../../../shared/types/creatorGallery';
import { sortByDate } from '../../../../../shared/utils';

type initialState = {
  galleries: CreatorGalleryGet[];
  loadingGet: boolean;
  isSuccess: boolean;
  error: ErrorType;
  loadingDelete: boolean;
  loadingDeleteId: string;
  errorDelete: string;
  errorDeleteItem: { id: string; message: '' } | null;
  isSuccessDelete: boolean;
};

export const initialState: initialState = {
  galleries: [],
  loadingGet: false,
  loadingDelete: false,
  errorDelete: '',
  isSuccessDelete: false,
  error: null,
  isSuccess: false,
  errorDeleteItem: null,
  loadingDeleteId: '',
};

const selectGalleries = (state: initialState) => state.galleries;
export const selectActiveGalleries = createSelector(
  selectGalleries,
  (galleries) => sortByDate(
    galleries.filter(
      (item) => item.status === 'active' || item.status === 'inactive',
    ) || [],
    'desc',
    'createGalleryActiveTimestamp',
  ),
);
export const selectDraftGalleries = createSelector(
  selectGalleries,
  (galleries) => sortByDate(
    galleries.filter((item) => item.status === 'draft') || [],
    'desc',
    'createGalleryTimestamp',
  ),
);
export const selectGallery = createSelector(
  [selectGalleries, (galleries, galleryId) => galleryId],
  (galleries, galleryId) => galleries?.find((item: any) => item.id === galleryId),
);

const galleryCreatorSlice = createSlice({
  name: 'galleryCreatorSlice',
  initialState,
  reducers: {
    setSuccessDeleteFalse(state) {
      state.isSuccessDelete = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchGetCreatorGalleries.fulfilled,
      (state, action: PayloadAction<CreatorGalleryGet[]>) => {
        state.error = null;
        state.loadingGet = false;
        state.galleries = action.payload;
      },
    );
    builder.addCase(
      fetchGetCreatorGalleries.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loadingGet = false;
      },
    );
    builder.addCase(fetchGetCreatorGalleries.pending, (state) => {
      state.loadingGet = true;
      state.error = null;
    });
    builder.addCase(
      fetchDeleteCreatorGallery.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.error = null;
        state.loadingDelete = false;
        state.isSuccessDelete = true;
        state.galleries = state.galleries.filter(
          (item) => item.id !== action.payload,
        );
      },
    );
    builder.addCase(
      fetchDeleteCreatorGallery.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload.message;
        state.loadingDelete = false;
        state.loadingDeleteId = '';
        state.errorDeleteItem = action.payload;
      },
    );
    builder.addCase(fetchDeleteCreatorGallery.pending, (state, action) => {
      state.loadingDelete = true;
      state.loadingDeleteId = action.meta.arg.id;
      state.error = null;
      state.errorDeleteItem = null;
    });
  },
});

export const { setSuccessDeleteFalse } = galleryCreatorSlice.actions;

export default galleryCreatorSlice.reducer;
