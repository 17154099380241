/* eslint-disable prefer-const */
import { Dispatch, SetStateAction } from 'react';
import { CreatorGalleryFile } from '../../../shared/types/commonGallery';
import { CartInteractionSection, ClientGalleryGet, FileBySection } from '../../../shared/types';
import { downloadFiles } from '../../../api/client/clientGallery';

export const getPathData = (): { businessName: string, galleryName: string } => {
  const path = window.location.href;
  const splitedPath = path.split('/');
  const businessName = splitedPath[2].split('.')[0];

  return ({
    businessName,
    galleryName: path?.split('/')[3],
  });
};

export const getAwsKey = (file: CreatorGalleryFile) => {
  if (file.awsKey) {
    return file.awsKey;
  }
  return file.id;
};

const awsDomain = `${process.env.REACT_APP_BASE_MEDIA_URL}/`;

export async function checkArchiveGeneratingStatus(
  dataReq: {
    awsKey: string;
    name: string;
    }[],
  orderId: string,
  setError: Dispatch<SetStateAction<string>>,
  setArchiveLink: Dispatch<SetStateAction<string>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
): Promise<any> {
  let cnt = 0;
  let interval: any;
  interval = setInterval(async () => {
    const result = await downloadFiles.getArchiveUrl(
      dataReq,
      orderId,
    );
    cnt++;
    if (result.status === 200) {
      clearInterval(interval);
      await setArchiveLink(result.data);
    } else if (cnt >= 100) {
      setIsLoading(false);
      clearTimeout(interval);
      setError('Archive preparing has failed. Try again later, please.');
    }
  }, 3000);
}

export async function checkArchiveStatus(
  url: string,
  orderId: string,
  setError: Dispatch<SetStateAction<string>>,
  setDownloadLink: Dispatch<SetStateAction<string>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  downloadAutomatically?: boolean,
  handleDownloadAutomatically?: (url: string, zipName: string) => void,
): Promise<any> {
  let cnt = 0;
  let interval: any;
  interval = setInterval(async () => {
    const result = await fetch(`${awsDomain}${orderId}.zip`);
    cnt++;
    if (result.status === 200) {
      clearInterval(interval);
      if (!downloadAutomatically) {
        setIsLoading(false);
      }
      setDownloadLink(`${awsDomain}${orderId}.zip`);
      if (downloadAutomatically) {
        handleDownloadAutomatically?.(`${awsDomain}${orderId}.zip`, `${orderId}.zip`);
      }
    } else if (cnt >= 100) {
      setIsLoading(false);
      clearTimeout(interval);
      setError('Archive preparing has failed. Try again later, please.');
    }
  }, 3000);
}

export const getCartSummaryData = (
  gallery: ClientGalleryGet | null,
  files: FileBySection[],
  freeFiles: FileBySection[],
) => {
  let subtotalLocal = 0;
  let sectionsDownloadsDisabledLocal = 0;
  let notDisabledLocal = 0;
  const cartSectionsLocal: (CartInteractionSection & {
      disabledDownlods?: boolean;
    })[] = gallery?.sections.map((item) => ({
      title: item.title,
      sectionId: item.id,
      files: [],
      freeFiles: [],
      disabledDownloads: !!item.disableDownloads,
    })) || [];

  cartSectionsLocal.forEach((item) => {
    files.forEach((sectionItem) => {
      if (sectionItem.sectionId === item?.sectionId) {
        item.files.push(sectionItem.file);
        subtotalLocal += Number(sectionItem.file.price || 0);
      }
    });
    freeFiles.forEach((sectionItem) => {
      if (sectionItem.sectionId === item?.sectionId) {
        item.freeFiles.push(sectionItem.file);
      }
    });
    if (item.disabledDownlods) {
      sectionsDownloadsDisabledLocal += 1;
    } else {
      notDisabledLocal += 1;
    }
  });
  return {
    subtotal: subtotalLocal,
    sectionsDownloadsDisabled: sectionsDownloadsDisabledLocal,
    notDisabled: notDisabledLocal,
    cartSections: cartSectionsLocal,
    sections: cartSectionsLocal as CartInteractionSection[],
  };
};
