import React, { useMemo } from 'react';
import {
  useTypedSelectorClient,
  useClientAppDispatch,
} from '../../../../../../shared/hooks/useTypedSelector';
import { SimpleGreyText } from '../../../../../../shared/styles';
import { addItemToToastQueue } from '../../../../../Client/redux/downloadingToast/downloadingToastSlice';
import { useAddFilesToCart } from '../../../../../Client/hooks/useAddFilesToCart';
import { ClientGallerySection } from '../../../../../../shared/types';
import { DownloadAllButtonElement } from './styled';
import { getIcon, getText } from '../../../../utils/helpers';

export const DownloadAllButton = ({
  section,
  hasToPay,
}: {
  section: ClientGallerySection;
  hasToPay: boolean;
}) => {
  const { handleAddToCart, requestStatus } = useAddFilesToCart();
  const gallery = useTypedSelectorClient((state) => state?.signIn?.gallery);

  const queue = useTypedSelectorClient(
    (state) => state.downloadingToast?.queue,
  );
  const dispatch = useClientAppDispatch();

  const isDownloading = useMemo(() => !!queue[section.id], []);
  const handleDownloadAll = () => {
    if (!gallery) return;
    if (!hasToPay) {
      dispatch(
        addItemToToastQueue({
          files: section.files,
          sectionName: section.title,
          sectionId: section.id,
        }),
      );
    } else {
      handleAddToCart(section.files);
    }
  };

  const isAddingToCart = requestStatus === 'loading' || requestStatus === 'pending';

  return (
    <DownloadAllButtonElement
      disabled={isAddingToCart || isDownloading}
      type="button"
      onClick={handleDownloadAll}
    >
      {getIcon(isAddingToCart, requestStatus === 'succeeded', hasToPay)}
      <SimpleGreyText fontWeight={400}>
        {getText(requestStatus === 'succeeded', hasToPay, isDownloading)}
      </SimpleGreyText>
    </DownloadAllButtonElement>
  );
};
