import styled from '@emotion/styled';
import { WhiteCardWithShadows } from '../../styles';
import { textBlack, textGrey } from '../../../constants/styles';
import { theme } from '../GlobalContainer/styled';

export const ToastContentContainer = styled.div`
  display: flex;
  flex-direction: row !important;
  width: 1px;
  align-items: flex-end;
  height: 280px !important;
  max-height: 280px !important;
  padding-top: 40px;
  left: calc(100% - 460px - 100px);
  z-index: 13000000;
  display: flex;
  background: transparent;
  flex-direction: column;
`;

export const ToastContentWrapper = styled.div`
  display: flex;
  flex-direction: row !important;
  width: 460px;
  height: fit-content !important;
  max-height: 280px !important;
  padding: 10px 10px 10px;
  display: flex;
  right: 74px;
  position: absolute;
  background: transparent;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 13000000;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${textGrey};
    border-radius: 163px;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-track {
    background: #dbdbdb;
    border-radius: 163px;
  }
`;

export const ToastContentItem = styled(WhiteCardWithShadows)`
  display: flex;
  flex-direction: row !important;
  width: 460px;
  height: 140px !important;
  border-radius: 10px;
  box-shadow: 2px 2px 3px 0px #E7E7E7, -2px -2px 3px 0px #E7E7E7;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
`;

export const ToastFileWrapper = styled.div`
  margin-right: 16px;
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  border-radius: 4px;
  position: relative;
  background: #c4c4c4;

  & > div {
    background: #c4c4c4;
  }
`;

export const ExpandArrowButton = styled.button`
  padding: 0px;
  transition: all 0.3s ease-in-out;

  & svg {
    transition: all 0.2s ease-in-out;
    color: ${textGrey};
  }
  &:hover {
    & svg {
      transition: all 0.2s ease-in-out;
      color: ${textBlack};
    }
  }
`;

export const DownloadAllSmall = styled.div`
  height: 320px;
  display: flex;
  align-items: end;
  left: calc(100% - 460px - 100px);
  z-index: 100000000;
  padding-bottom: 10px;
  width: fit-content;
  margin-right: 20px;

  & button {
    margin: 0;
  }

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    left: calc(100% - 40px);
    margin: -40px 0 0 0;

    & button {
      box-shadow: none;
      border: 1px solid rgba(235, 235, 235, 0.8);
      max-width: 40px;
      max-height: 40px;

      &:hover {
        box-shadow: none;
      }

      &:active {
        box-shadow: none;
      }
    }
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    padding: 0px 16px;
  }
`;
