import React from 'react';
import { useTranslation } from 'react-i18next';
import { SimpleBlackText } from '../../../../shared/styles';
import { LandingContentContainer } from '../LandingContentContainer';
import {
  LandingTermsAndPrivacyWrapper,
  TermsAndPrivacyListTitle,
  TermsAndPrivacyText,
} from './styled';
import { TermsAndPrivacyTopScreen } from './TermsAndPrivacyTopScreen';
import termsImage from '../../../../assets/image/termsAndPrivacy/terms.png';
import { OurDisclosuresPoints } from './LandingTermsPoints/OurDisclosuresPoints';
import { IntroductionPoints } from './LandingTermsPoints/IntroductionPoints';
import { LicensePoints } from './LandingTermsPoints/LicensePoints';
import { AccountsPoints } from './LandingTermsPoints/AccountsPoints';
import { PlatformSummaryPoints } from './LandingTermsPoints/PlatformSummaryPoints';
import { HowWorksPoints } from './LandingTermsPoints/HowWorksPoints';
import { CommunicationPoints } from './LandingTermsPoints/CommunicationPoints';
import { PaymentsToClientsPoints } from './LandingTermsPoints/PaymentsToClientsPoints';
import { PaymentsToMembersPoints } from './LandingTermsPoints/PaymentsToMembersPoints';
import { RefundsAndCancelPoints } from './LandingTermsPoints/RefundsAndCancelPoints';
import { IntellectualPropertyPoints } from './LandingTermsPoints/IntellectualPropertyPoints';
import { UploadedContentPoints } from './LandingTermsPoints/UploadedContentPoints';
import { UploadedContentToSocMediaPoints } from './LandingTermsPoints/UploadedContentToSocMediaPoints';
import { HowPlatformWorksPoints } from './LandingTermsPoints/HowPlatformWorksPoints';
import { AustralianLawPoints } from './LandingTermsPoints/AustralianLawPoints';
import { ExclusionsPoints } from './LandingTermsPoints/ExclusionsPoints';
import { LimitationsPoints } from './LandingTermsPoints/LimitationsPoints';
import { TerminationPoints } from './LandingTermsPoints/TerminationPoints';
import { NoticeApplePoints } from './LandingTermsPoints/NoticeApplePoints';
import { PrivacyPoints } from './LandingTermsPoints/PrivacyPoints';
import { GeneralPoints } from './LandingTermsPoints/GeneralPoints';
import { DefinitionsPoints } from './LandingTermsPoints/DefinitionsPoints';
import { TermsAndPrivacyContactInfo } from './TermsAndPrivacyContactInfo';
import { RenewalPolicyPoints } from './LandingTermsPoints/RenewalPolicyPoints';

export const LandingTerms: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <LandingContentContainer>
      <LandingTermsAndPrivacyWrapper>
        <TermsAndPrivacyTopScreen
          title={t('terms.titleTerms')}
          imageUrl={termsImage}
          lastUpdateDate="14 February 2025"
        />
        <TermsAndPrivacyListTitle>
          {t('terms.ourDisclosures')}
        </TermsAndPrivacyListTitle>
        <SimpleBlackText>{t('terms.ourCompleteTerms')}</SimpleBlackText>
        <OurDisclosuresPoints />
        <TermsAndPrivacyText>{t('terms.nothingLimit')}</TermsAndPrivacyText>
        <IntroductionPoints />
        <LicensePoints />
        <AccountsPoints />
        <PlatformSummaryPoints />
        <HowWorksPoints />
        <CommunicationPoints />
        <PaymentsToClientsPoints />
        <PaymentsToMembersPoints />
        <RefundsAndCancelPoints />
        <IntellectualPropertyPoints />
        <UploadedContentPoints />
        <UploadedContentToSocMediaPoints />
        <HowPlatformWorksPoints />
        <AustralianLawPoints />
        <ExclusionsPoints />
        <LimitationsPoints />
        <TerminationPoints />
        <RenewalPolicyPoints />
        <NoticeApplePoints />
        <PrivacyPoints />
        <GeneralPoints />
        <DefinitionsPoints />
        <TermsAndPrivacyContactInfo />
      </LandingTermsAndPrivacyWrapper>
    </LandingContentContainer>
  );
};
