import React from 'react';
import { Slide, Stack } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { ButtonMoveToTopWrapper } from './styled';
import arrowToTop from '../../../assets/image/arrowToTop.svg';
import { RoundButton } from '../../styles';
import { useVisibleOnScroll } from '../../hooks/useVisibleOnScroll';
import { useTypedSelectorClient } from '../../hooks/useTypedSelector';

type ButtonMoveToTopProps = {
  visibleImportant?: boolean;
  margins?: boolean;
  hide?: boolean;
}

export const ButtonMoveToTop: React.FC<ButtonMoveToTopProps> = ({
  visibleImportant,
  margins,
  hide,
}) => {
  const visible = useVisibleOnScroll(500);
  const location = useLocation();
  const isOpen = useTypedSelectorClient((state) => state?.swiper?.isOpen);

  const handleClick = () => {
    window?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    document?.querySelector('#scrolled-container')?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  if ((hide && location.pathname !== '/') || isOpen) {
    return null;
  }

  return (
    <Slide direction="up" in={visible || visibleImportant}>
      <ButtonMoveToTopWrapper margins={margins} id="scroll-button">
        <Stack pb="10px">
          <RoundButton
            size="large"
            type="button"
            onClick={handleClick}
          >
            <img src={arrowToTop} alt="top" />
          </RoundButton>
        </Stack>
      </ButtonMoveToTopWrapper>
    </Slide>
  );
};
