import { useFormikContext } from 'formik';
import { ReactElement } from 'react';
import { useAuth } from '@clerk/clerk-react';
import { useErrors } from '../../../../../hooks/useErrors';
import { getGalleryDataForRequest } from '../../../../../utils/getGalleryDataForRequest';
import {
  useCreatorAppDispatch,
  useTypedSelectorCreator,
} from '../../../../../../../shared/hooks/useTypedSelector';
import { fetchSaveCreatorGallery } from '../../../../../redux/gallery/singleGallery/singleGalleryCreatorThunk';
import { GalleryStatus } from '../../../../../../../shared/types/commonGallery';
import {
  CreatorGalleryForm,
  CreatorGalleryPut,
} from '../../../../../../../shared/types/creatorGallery';

type ReturnObj = {
  handleUpdateGallery: (
    data: CreatorGalleryForm,
    status: GalleryStatus
  ) => void;
  isLoading: boolean;
  isSuccess: boolean;
  errorAlert: ReactElement;
};

export const useSaveGallery = (): ReturnObj => {
  const { setFieldError } = useFormikContext<CreatorGalleryForm>();

  const dispatch = useCreatorAppDispatch();
  const isSuccess = useTypedSelectorCreator(
    (state) => state.gallery.isSuccessSave,
  );
  const isLoading = useTypedSelectorCreator(
    (state) => state.gallery.loadingSave,
  );
  const error = useTypedSelectorCreator((state) => state.gallery.errorSave);
  const currentGallery = useTypedSelectorCreator(
    (state) => state.gallery.gallery,
  );
  const { getToken } = useAuth();
  const errorAlert = useErrors(error, setFieldError);

  const updateGallery = async (id: string, gallery: CreatorGalleryPut) => {
    dispatch(fetchSaveCreatorGallery({ id, gallery, getToken }));
  };

  const handleUpdateGallery = (
    data: CreatorGalleryForm,
    status: GalleryStatus,
  ) => {
    const dataForRequest = getGalleryDataForRequest(data, status);

    updateGallery(dataForRequest.id, {
      ...dataForRequest,
      createGalleryActiveTimestamp:
        currentGallery?.createGalleryActiveTimestamp,
    });
  };

  return {
    handleUpdateGallery,
    isLoading,
    isSuccess,
    errorAlert,
  };
};
