import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, IconButton, TableCell, TableRow, Tooltip,
} from '@mui/material';
import { ForwardToInbox, Error } from '@mui/icons-material';
import {
  CopyLinkIcon,
  DownloadIcon,
  TickIcon,
} from '../../../../../../../../../shared/icons';
import { TextAndIconWrapper } from '../../../../Settings/UsageAndBilling/styled';
import { Order, OrderStatus } from '../../../../../../../types/sales';
import { useTypedSelectorCreator } from '../../../../../../../../../shared/hooks/useTypedSelector';
import {
  getFormattedDateOrDateName,
  getFormattedTime,
} from '../../../../../../../../../shared/utils';
import { TextLimited } from '../../../../Sales/styled';
import { downloadAs, getCSV } from '../../../../../../../utils/csv';
import { useResendDownloadLink } from './hooks/useResendDownloadLink';
import * as variables from '../../../../../../../../../constants/styles';
import { useCopyValue } from '../../../../../../../../../shared/components/CommonCopyInput/useCopyValue';
import { PinkLoader } from '../../../../../../../../../shared/components/Loaders/PinkLoader/PinkLoader';
import {
  DotsMenu,
  DotsMenuButton,
} from '../../../../../../../../../shared/components/DotsMenu/DotsMenu';
import {
  MediumBlackText,
  SimpleBlackText,
} from '../../../../../../../../../shared/styles';
import { ErrorMessage } from '../../../../../../../../../shared/components/styled';

type OrderRowProps = {
  row: Order;
  galleryName: string;
};

const getSendIcon = (isLoading: boolean, isSuccess: boolean, error: string) => {
  if (isLoading) {
    return <PinkLoader size="xs" />;
  }
  if (isSuccess) {
    return (
      <Box
        sx={{ width: '24px', height: '24px' }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <TickIcon
          sx={{
            width: '16px',
            height: '16px',
            color: variables.colorPink,
          }}
        />
      </Box>
    );
  }
  if (error) {
    return <Error sx={{ color: variables.colorError }} />;
  }
  return <ForwardToInbox sx={{ color: variables.colorPink }} />;
};

export const OrderRow: React.FC<OrderRowProps> = ({ row, galleryName }) => {
  const { t } = useTranslation('sales');
  const currentUser = useTypedSelectorCreator(
    (state) => state.creator.currentUser,
  );
  const {
    isLoading, isSuccess, error, handleResendDownloadLink,
  } = useResendDownloadLink();
  const { isCopied, handleCopy } = useCopyValue();

  const date = getFormattedDateOrDateName(
    row.createOrderTimestamp,
    currentUser?.dateFormat || '',
    currentUser?.timezone,
  );
  const time = getFormattedTime(
    row.createOrderTimestamp,
    currentUser?.timezone,
    currentUser?.dateFormat || '',
  );

  const handleDownload = () => {
    const csvContent = getCSV(row);
    downloadAs(csvContent, galleryName);
  };
  const handleCopyArchiveLink = (e: React.MouseEvent<HTMLButtonElement>) => {
    const link = `${process.env.REACT_APP_BASE_MEDIA_URL}/${row.id}.zip`;
    handleCopy(e, link);
  };

  return (
    <TableRow key={row.id} hover>
      <TableCell
        sx={{
          minWidth: '100px',
          borderBottom: '0px',
          paddingBottom: '8px',
          paddingTop: '8px',
          position: 'relative',
          paddingRight: '40px',
          paddingLeft: '0px',
        }}
        align="left"
      >
        <TextLimited>{row.email}</TextLimited>
      </TableCell>
      <TableCell
        sx={{ borderBottom: '0px', paddingBottom: '8px', paddingTop: '8px' }}
        align="left"
      >
        {date}
      </TableCell>
      <TableCell
        sx={{
          whiteSpace: 'pre',
          borderBottom: '0px',
          paddingBottom: '8px',
          paddingTop: '8px',
        }}
        align="left"
      >
        {time}
      </TableCell>
      <TableCell
        sx={{
          textTransform: 'capitalize',
          borderBottom: '0px',
          paddingBottom: '8px',
          paddingTop: '8px',
        }}
        align="left"
      >
        {row.status}
      </TableCell>
      <TableCell
        sx={{ borderBottom: '0px', paddingBottom: '8px', paddingTop: '8px' }}
        align="center"
      >
        $
        {row.amount / 100}
      </TableCell>
      <TableCell
        align="center"
        sx={{
          borderBottom: '0px',
          paddingBottom: '8px',
          paddingTop: '8px',
          position: 'relative',
          paddingRight: '0px',
        }}
      >
        <TextAndIconWrapper>
          {row.files.length ? (
            <button
              type="button"
              onClick={handleDownload}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <DownloadIcon />
            </button>
          ) : (
            '-'
          )}
        </TextAndIconWrapper>
      </TableCell>
      <TableCell
        sx={{ borderBottom: '0px', padding: '8px 0px 8px 8px' }}
        align="center"
      >
        {row.status === OrderStatus.confirmed && (

        <DotsMenu
          closeOnClick={false}
          close={(isCopied || isSuccess) && !error}
          options={[
            <DotsMenuButton type="button" onClick={handleCopyArchiveLink}>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} gap="8px">
                {isCopied ? (
                  <Box
                    sx={{ width: '24px', height: '24px' }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <TickIcon
                      sx={{
                        width: '16px',
                        height: '16px',
                        color: variables.colorPink,
                      }}
                    />
                  </Box>
                ) : (
                  <CopyLinkIcon sx={{ color: variables.colorPink }} />
                )}
                <MediumBlackText>Copy download link</MediumBlackText>
              </Box>
            </DotsMenuButton>,
            <DotsMenuButton
              type="button"
              onClick={() => handleResendDownloadLink(row.id)}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} gap="8px">
                {getSendIcon(isLoading, isSuccess, error)}
                {error ? (
                  <ErrorMessage>{error}</ErrorMessage>
                ) : (
                  <MediumBlackText>
                    Resend download email
                  </MediumBlackText>
                )}
              </Box>
            </DotsMenuButton>,
          ]}
        />
        )}
      </TableCell>

    </TableRow>
  );
};
