import React from 'react';
import { useTranslation } from 'react-i18next';
import { LandingNumberedLi } from '../LandingNumberedLi';
import {
  TermsAndPrivacyInsideListWrapper,
  TermsAndPrivacyListItem,
  TermsAndPrivacyListNoMarkers,
  TermsAndPrivacyListWrapper,
} from '../styled';
import { TermsAndPrivacyNumberedList } from '../TermsAndPrivacyNumberedList';

export const HowWorksPoints: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <TermsAndPrivacyNumberedList
      startWith={6}
      listTitle={t('terms.howWorks')}
      pointsNameForTrans="howWorksPoints"
    >
      <LandingNumberedLi>
        Use of the Platform for Content Delivery and Sales
        <TermsAndPrivacyInsideListWrapper>
          <LandingNumberedLi>
            The Platform enables Members to create and manage digital galleries
            to deliver content to Clients. Members may upload, organize, and
            distribute digital content, including but not limited to photos and
            videos, through unique gallery links generated by the Platform.
          </LandingNumberedLi>
          <LandingNumberedLi>
            Members may use the Platform to facilitate the sale of additional
            content beyond the originally agreed-upon deliverables. This may
            include extra images, video clips, raw files, alternative edits, or
            other digital assets (collectively referred to as “Bonus Content”).
          </LandingNumberedLi>
          <LandingNumberedLi>
            Members are responsible for setting pricing, granting or restricting
            download permissions, and managing the availability of content
            within the galleries they create.
          </LandingNumberedLi>
          <LandingNumberedLi>
            Clients may access the Platform through the gallery link provided by
            the Member and interact with content in the following ways:
            <TermsAndPrivacyListWrapper hasBigBottomMargin={false}>
              <TermsAndPrivacyListItem listMarker="disc">
                Viewing and downloading files included in their original project
                scope.
              </TermsAndPrivacyListItem>
              <TermsAndPrivacyListItem listMarker="disc">
                Purchasing additional files made available for sale by the
                Member.
              </TermsAndPrivacyListItem>
              <TermsAndPrivacyListItem style={{ marginBottom: '0px' }} listMarker="disc">
                Favoriting or commenting on specific files where permitted by
                the Member.
              </TermsAndPrivacyListItem>
            </TermsAndPrivacyListWrapper>
          </LandingNumberedLi>
          <LandingNumberedLi>
            Payment for additional content is processed through Stripe. Clients
            must complete payment via the Platform before access to paid content
            is granted.
          </LandingNumberedLi>
          <LandingNumberedLi>
            Once payment is received, the purchased content is made available
            for download in accordance with the permissions set by the Member.
          </LandingNumberedLi>
          <LandingNumberedLi>
            Delivrable is not responsible for ensuring that Clients download or
            retain access to purchased content after a gallery is removed or
            deactivated. It is the responsibility of the Member to set access
            limitations and inform Clients of any impending gallery expiration.
          </LandingNumberedLi>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        Payments and Fees:
        <TermsAndPrivacyInsideListWrapper>
          <TermsAndPrivacyListItem listMarker="disc">
            All transactions conducted through the Platform are processed by
            Stripe. By using the Platform, Members acknowledge and agree to
            Stripe’s payment processing terms, which may vary based on
            jurisdiction.
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            Delivrable does not charge a commission on sales unless the Member
            is subscribed under a Free Membership Tier, in which case a
            {' '}
            <span className="bold">Deferred Payment Service Fee of 15%</span>
            {' '}
            applies to each transaction. This fee is deducted automatically
            before payouts are processed to the Member’s Stripe account.
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            Payment processing fees charged by Stripe will vary between
            {' '}
            <span className="bold">1.7% and 6%</span>
            , depending on the Client’s
            payment method, currency conversion, and the Member’s country of
            operation. These fees are subject to change at Stripe’s discretion
            and are not controlled by Delivrable.
            {' '}
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            Payouts to Members are subject to
            {' '}
            <span className="bold">Stripe’s standard processing times</span>
            ,
            which may take between 2-5 business days depending on the Member’s
            country and Stripe’s internal policies.
            {' '}
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem
            listMarker="disc"
            style={{ marginBottom: '0px' }}
          >
            The Member is solely responsible for ensuring that their payment
            details are correct and that their Stripe account is properly set up
            to receive payouts. Delivrable is not liable for failed payments,
            processing delays, or errors resulting from incorrect banking
            details or payment processing failures.
            {' '}
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        Refunds and Chargebacks:
        <TermsAndPrivacyInsideListWrapper>
          <TermsAndPrivacyListItem listMarker="disc">
            All sales conducted through the Platform are final, and Delivrable
            does not facilitate refunds. Any refund requests must be handled
            directly between the Member and the Client.
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            If a Client requests a refund, the Member must process the refund
            through their Stripe Express dashboard. The Member is responsible
            for any Stripe fees incurred as a result of a refund or chargeback.
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem
            listMarker="disc"
            style={{ marginBottom: '0px' }}
          >
            Delivrable
            {' '}
            <span className="bold">shall not be held liable</span>
            {' '}
            for any chargebacks, disputes, or losses incurred as a result of
            refund requests, fraudulent transactions, or non-payment by a
            Client. The Member assumes full financial responsibility for
            disputes arising from transactions conducted through the Platform.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        Content Ownership and Control:
        <TermsAndPrivacyInsideListWrapper>
          <TermsAndPrivacyListItem listMarker="disc">
            The Member retains ownership of all content uploaded to the Platform
            and is solely responsible for ensuring they have the necessary
            rights and licenses to distribute and sell such content.
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            Delivrable does not claim ownership of any content uploaded by
            Members but reserves the right to store, transmit, and display such
            content within the Platform solely for the purpose of enabling
            Platform functionality.
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            Members are responsible for ensuring that any Bonus Content made
            available for sale is accurately represented and that pricing is
            clearly displayed within the Platform.
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem
            listMarker="disc"
            style={{ marginBottom: '0px' }}
          >
            Delivrable does not verify or review content uploaded by Members and
            is not liable for any claims relating to intellectual property
            infringement, unauthorized distribution, or misrepresentation of
            content.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        Platform Features and Limitations:
        <TermsAndPrivacyInsideListWrapper>
          <TermsAndPrivacyListItem listMarker="disc">
            The Platform provides optional engagement features, including:
            <TermsAndPrivacyListWrapper hasBigBottomMargin>
              <TermsAndPrivacyListItem listMarker="disc">
                <span className="bold">Favoriting (Liking):</span>
                {' '}
                Clients may
                save preferred images or video files.
              </TermsAndPrivacyListItem>
              <TermsAndPrivacyListItem listMarker="disc">
                <span className="bold">Commenting:</span>
                {' '}
                Clients may leave
                feedback or revision requests on specific files.
              </TermsAndPrivacyListItem>
              <TermsAndPrivacyListItem listMarker="disc">
                <span className="bold">Sharing:</span>
                {' '}
                Clients may distribute
                gallery links with internal stakeholders.
              </TermsAndPrivacyListItem>
            </TermsAndPrivacyListWrapper>
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            Members may enable or disable engagement features at their
            discretion.
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            Delivrable does not provide built-in project management, invoicing,
            or contract services. Members are responsible for conducting any
            pre-sale agreements with Clients outside the Platform.
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem
            listMarker="disc"
            style={{ marginBottom: '0px' }}
          >
            The Platform is intended solely for content delivery and sales. Any
            misuse of the Platform for unauthorized transactions, file-sharing
            schemes, or any activity deemed in violation of these Terms may
            result in the immediate termination of the Member’s account.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        Compliance and Legal Responsibilities
        <TermsAndPrivacyInsideListWrapper>
          <TermsAndPrivacyListItem listMarker="disc">
            Members are responsible for compliance with all applicable tax laws,
            including reporting and remitting any applicable VAT, GST, or sales
            tax on transactions.
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            For Members based in the United States, Stripe may issue tax
            reporting forms (e.g., Form 1099-K) when applicable. It is the
            Member’s responsibility to review their tax obligations and ensure
            proper compliance.
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            Delivrable does not act as a tax collector and does not withhold or
            remit any taxes on behalf of its Members.
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem
            listMarker="disc"
            style={{ marginBottom: '0px' }}
          >
            Delivrable may, at its sole discretion, introduce additional
            compliance measures, including but not limited to
            {' '}
            <span className="bold">ID verification</span>
            , additional payment
            restrictions, or regional limitations, in order to maintain
            compliance with regulatory requirements.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        Ideas
        <TermsAndPrivacyInsideListWrapper>
          <TermsAndPrivacyListItem listMarker="disc">
            A Member may also use the Platform to post an accurate and complete
            description of additional goods they are willing to offer a Client
            through the Platform and the cost, including any GST payable (
            <span className="bold">Idea</span>
            ).
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem
            listMarker="disc"
            style={{ marginBottom: '0px' }}
          >
            If a Client wishes to acquire the goods offered in an Idea, the
            Client can (as applicable) contact the Client off Platform to create
            a new Project based on the Idea or if the Idea is ready for
            purchase, can pay the Idea Project Fees via the Platform and
            download the Idea Output Data.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        Output Data Interactions
        <TermsAndPrivacyListNoMarkers>
          The Platform contains a functionality that allows Clients to
          “favourite”, “comment”, and “download” or “add to cart” (enabling them
          to download Output Data after purchase) for each specific Output Data
          item. Any personal information collected by us via this functionality
          will be dealt with by us in accordance with clause 21 of these Terms
        </TermsAndPrivacyListNoMarkers>
      </LandingNumberedLi>
    </TermsAndPrivacyNumberedList>
  );
};
