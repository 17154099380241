import React, { useContext } from 'react';
import { Zoom } from '@mui/material';
import { ChangeColorButton, GradientCover, IconsWrapper } from './styled';
import {
  CartIcon,
  HeartIcon,
  InstantDownload,
  CommentIcon,
  HeartFilledIcon,
} from '../../icons/FileIcons';
import { useLike } from '../../../modules/GalleryView/hooks/buttons/useLike';
import { useAddToCart } from '../../../modules/GalleryView/hooks/buttons/useAddToCart';
import { ClientGalleryFile, GalleryComment } from '../../types';
import { useComment } from '../../../modules/GalleryView/hooks/buttons/useComment';
import { ClientOnboardingContext } from '../../../modules/Client/context/CreatorOnboardingContext';
import { useCheckWindowSize } from '../../hooks/useCheckWindowSize';
import { useClientAppDispatch, useTypedSelectorGallery } from '../../hooks/useTypedSelector';
import { PinkLoader } from '../Loaders/PinkLoader/PinkLoader';
import { addItemToToastQueue } from '../../../modules/Client/redux/downloadingToast/downloadingToastSlice';

type GradientCoverWithActionsProps = {
  isInstantlyDownloadable: boolean;
  isAllowCommenting: boolean;
  disabledAll?: boolean;
  id: string;
  sectionId?: string;
  isFileLiked?: boolean;
  isFileInCart?: boolean;
  file: ClientGalleryFile | null;
  isGradientVisible?: boolean;
  comments?: GalleryComment[];
  isLimitedFreeFile: boolean;
};

export const GradientCoverWithActions: React.FC<
  GradientCoverWithActionsProps
> = ({
  isInstantlyDownloadable,
  isAllowCommenting,
  disabledAll,
  id,
  sectionId,
  isFileLiked,
  isFileInCart,
  isLimitedFreeFile,
  file,
  isGradientVisible,
  comments,
}) => {
  const { handleLike, isLoadingFav } = useLike(!!isFileLiked);
  const dispatch = useClientAppDispatch();
  const {
    handleClickCart, hoverElement, isLoading: isLoadingCart,
  } = useAddToCart(
    !!isFileInCart,
    sectionId || '',
    !!isLimitedFreeFile,
  );
  const gallery = useTypedSelectorGallery((state) => state?.gallery?.gallery);

  const { handleOpenComments, commentArea } = useComment({
    isFileOpen: true,
    file,
    comments,
    fileId: id,
    gallery,
  });
  const { isStudy: isStudyClient } = useContext(ClientOnboardingContext);
  const { screenSize } = useCheckWindowSize();

  const handleDownloadFile = () => {
    if (file && sectionId) {
      dispatch(
        addItemToToastQueue({
          files: [file],
          sectionName: file?.sectionName || 'Single file',
          sectionId,
          instantlyDownloadable: true,
        }),
      );
    }
  };

  if (screenSize !== 'desktop') {
    return null;
  }

  return (
    <>
      <GradientCover
        id="cover-gradient"
        isStudy
        isGradientVisible={!!isGradientVisible}
      >
        <IconsWrapper id="client-buttons-over-item-step">
          {(isInstantlyDownloadable || isStudyClient) && (
            <button
              disabled={!!disabledAll}
              type="button"
              id="client-instantly-downloadable-step"
              onClick={handleDownloadFile}
            >
              <InstantDownload />
            </button>
          )}
          <ChangeColorButton
            isClicked={!!isFileLiked}
            disabled={!!disabledAll || isLoadingFav}
            type="button"
            onClick={() => handleLike(file!)}
            id="client-like-button-step"
          >
            {isFileLiked && (
              <Zoom in={isFileLiked}>
                <div style={{ display: 'flex' }}>
                  {isLoadingFav ? <PinkLoader size="xs" /> : <HeartFilledIcon />}
                </div>
              </Zoom>
            )}
            {!isFileLiked && (
              <Zoom in={!isFileLiked}>
                <div style={{ display: 'flex' }}>
                  {isLoadingFav ? <PinkLoader size="xs" /> : <HeartIcon />}
                </div>
              </Zoom>
            )}
          </ChangeColorButton>
          <ChangeColorButton
            isClicked={isFileInCart || isLimitedFreeFile}
            disabled={!!disabledAll || isLoadingCart}
            type="button"
            onClick={() => handleClickCart(file as ClientGalleryFile)}
            style={{ position: 'relative' }}
            id="client-download-cart-button-step"
          >
            {hoverElement}
            {(isFileInCart || isLimitedFreeFile) && (
              <Zoom in={isFileInCart || isLimitedFreeFile}>
                <div style={{ display: 'flex' }}>
                  {isLoadingCart ? <PinkLoader size="xs" /> : <CartIcon />}
                </div>
              </Zoom>
            )}
            {!(isFileInCart || isLimitedFreeFile) && (
              <Zoom in={!(isFileInCart || isLimitedFreeFile)}>
                <div style={{ display: 'flex' }}>
                  {isLoadingCart ? <PinkLoader size="xs" /> : <CartIcon />}
                </div>
              </Zoom>
            )}
          </ChangeColorButton>
          {(isAllowCommenting || isStudyClient) && (
            <button
              aria-label="comment"
              disabled={!!disabledAll}
              type="button"
              style={{ position: 'relative' }}
              onClick={handleOpenComments}
              id="client-comment-button-step"
            >
              <CommentIcon />
            </button>
          )}
        </IconsWrapper>
      </GradientCover>
      {!disabledAll && commentArea}
    </>
  );
};
