import styled from '@emotion/styled';

export const LikesOrDownloadsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: calc(100% + 30px);
`;

export const LikesOrDownloadsFileWrapper = styled.div`
  margin-right: 30px;
  margin-bottom: 30px;
  width: 103px;
  height: 104px;
  min-width: 103px;
  min-height: 104px;
  border-radius: 4px;
  position: relative;
  background: #c4c4c4;

  & > div {
    background: #c4c4c4;
  }
`;

export const CoverWrapper = styled.div`
  position: 
`;

export const LikesOrDownloadsIconWrapper = styled.div`
  position: absolute;
  top: 6px;
  right: 8px;
  cursor: pointer;
  z-index: 1000000;
  & svg {
    color: white;
    width: 20px;
    height: 20px;
  }

  &:hover {
    div {
      opacity: 1;
    }
  }
`;

export const FileCardFileWrapper = styled.div`
  margin-right: 16px;
  width: 45px;
  height: 45px;
  min-width: 45px;
  min-height: 45px;
  border-radius: 4px;
  position: relative;
  background: #c4c4c4;

  & > div {
    background: #c4c4c4;
  }
`;
