import React, { CSSProperties } from 'react';
import { LoaderWrapper } from './styled';
import { BaseColors } from '../../../types';
import * as variables from '../../../../constants/styles';

export const PinkLoader: React.FC<{ size?: 'xs' | 'sm', color?: BaseColors, sx?: CSSProperties }> = ({
  color = 'pink',
  size,
  sx,
}) => (
  <LoaderWrapper style={sx} size={size} color={color}>
    <div className="container">
      <svg version="1.1" viewBox="0 0 64 64" width="1em" xmlns="http://www.w3.org/2000/svg" id="spinner">
        <circle className="path-gradient" cx="32" cy="32" r="28" fill="none" stroke="url(#sGD)" strokeWidth="4" />
        <path className="path-solid" d="M 32,4 A 28 28,0,0,0,32,60" fill="none" stroke="#000" strokeWidth="4" strokeLinecap="round" />

        <defs>
          <linearGradient id="sGD" gradientUnits="userSpaceOnUse" x1="32" y1="0" x2="32" y2="64">
            <stop stopColor={color === 'pink' ? '#F180B2' : variables.colorGreyLight} offset="0.1" stopOpacity="0" className="stop1" />
            <stop stopColor={color === 'pink' ? 'rgba(241, 128, 178, 0)' : variables.colorGreyLight} offset=".9" stopOpacity="1" className="stop2" />
          </linearGradient>
        </defs>

      </svg>
    </div>
  </LoaderWrapper>
);
