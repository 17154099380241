import React from 'react';
import { useTranslation } from 'react-i18next';
import { LandingNumberedLi } from '../LandingNumberedLi';
import { TermsAndPrivacyNumberedList } from '../TermsAndPrivacyNumberedList';
import {
  TermsAndPrivacyText,
  TermsAndPrivacyInsideListWrapper,
  TermsAndPrivacyListNoMarkers,
  TermsAndPrivacyListItem,
} from '../styled';

export const PaymentsToMembersPoints: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <TermsAndPrivacyNumberedList
      startWith={9}
      listTitle={t('terms.paymentsToMembers')}
      pointsNameForTrans="paymentsToMembersPoints"
    >
      <LandingNumberedLi>
        Applicability
        <TermsAndPrivacyInsideListWrapper style={{ paddingLeft: 0 }}>
          <TermsAndPrivacyListItem noPaddingLeft listMarker="none">
            This clause applies to you, a Member, and governs all financial
            transactions made through the Delivrable platform.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        Service Fees & Payment Processing
        <TermsAndPrivacyInsideListWrapper style={{ paddingLeft: 0 }}>
          <TermsAndPrivacyListItem noPaddingLeft listMarker="none">
            In consideration for providing the Delivrable SaaS services and
            platform, we charge you, a Member, service fees, including
            third-party payment processing fees, based on your Membership type,
            as outlined on the Platform and in the Membership Terms and
            Conditions.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        Deferred Payment Service Fee (for Free Tier Members)
        <TermsAndPrivacyInsideListWrapper style={{ paddingLeft: 0 }}>
          <TermsAndPrivacyListItem noPaddingLeft listMarker="none">
            If you are on a
            {' '}
            <span className="bold">
              free membership tier, a 15% Deferred Payment Service Fee
            </span>
            {' '}
            will be deducted from your gallery sales before funds are
            transferred to your account. This fee is automatically processed
            through Stripe before payout.
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem noPaddingLeft listMarker="none">
            For the avoidance of doubt, if you are on a
            {' '}
            <span className="bold">paid membership tier</span>
            , you are not
            subject to the Deferred Payment Service Fee. However, you will still
            be responsible for any applicable third-party payment processing
            fees charged by Stripe.
            {' '}
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        Payout Schedule & Processing Times
        <TermsAndPrivacyInsideListWrapper style={{ paddingLeft: 0 }}>
          <TermsAndPrivacyListItem noPaddingLeft listMarker="none">
            Payments for completed sales will be deposited into your linked
            {' '}
            <span className="bold">Stripe Express account</span>
            {' '}
            upon successful
            payment by your client. Payouts are typically processed within
            {' '}
            <span className="bold">2-5 business days</span>
            , subject to Stripe’s
            processing times and country-specific regulations.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        Refunds & Chargebacks
        <TermsAndPrivacyInsideListWrapper style={{ paddingLeft: 0 }}>
          <TermsAndPrivacyListItem noPaddingLeft listMarker="none">
            Creators are
            {' '}
            <span className="bold">solely responsible</span>
            {' '}
            for
            managing refunds, chargebacks, and disputes related to their gallery
            sales. This process must be executed via their
            {' '}
            <span className="bold">Stripe Express dashboard</span>
            .
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyInsideListWrapper>
            <TermsAndPrivacyListItem listMarker="disc">
              <span className="bold">No Refund Policy:</span>
              {' '}
              Delivrable
              operates under a
              {' '}
              <span className="bold">strict no-refund policy</span>
              {' '}
              for digital
              content. However, Creators may choose to issue refunds at their
              discretion.
            </TermsAndPrivacyListItem>
            <TermsAndPrivacyListItem listMarker="disc">
              <span className="bold">Liability:</span>
              {' '}
              The Creator assumes
              {' '}
              <span className="bold">all financial liability</span>
              {' '}
              for refunds,
              chargeback fees, and any associated costs incurred through Stripe.
              {' '}
            </TermsAndPrivacyListItem>
            <TermsAndPrivacyListItem listMarker="disc">
              <span className="bold">Dispute Resolution:</span>
              {' '}
              If a client
              files a dispute or chargeback, Stripe’s dispute resolution process
              will apply, and the Creator is responsible for responding and
              managing the resolution through Stripe’s system.
              {' '}
            </TermsAndPrivacyListItem>
          </TermsAndPrivacyInsideListWrapper>
          <TermsAndPrivacyListItem noPaddingLeft listMarker="none">
            Delivrable does
            {' '}
            <span className="bold">not</span>
            {' '}
            facilitate refunds
            or intervene in disputes between Creators and their clients.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        Liability for Failed Payments & Fraudulent Transactions
        <TermsAndPrivacyInsideListWrapper style={{ paddingLeft: 0 }}>
          <TermsAndPrivacyListItem noPaddingLeft listMarker="none">
            Delivrable is
            {' '}
            <span className="bold">not responsible</span>
            {' '}
            for
            failed payments, fraudulent transactions, or unauthorized purchases
            made through the platform.
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyInsideListWrapper>
            <TermsAndPrivacyListItem listMarker="disc">
              <span className="bold">Creators assume all financial risk</span>
              {' '}
              for transactions, including fraudulent purchases and chargebacks.
            </TermsAndPrivacyListItem>
            <TermsAndPrivacyListItem listMarker="disc">
              In the event of a
              {' '}
              <span className="bold">failed payment</span>
              ,
              Stripe may attempt to reprocess the payment. However, Delivrable
              holds no liability for incomplete transactions.
            </TermsAndPrivacyListItem>
          </TermsAndPrivacyInsideListWrapper>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        Taxes & Compliance
        <TermsAndPrivacyInsideListWrapper style={{ paddingLeft: 0 }}>
          <TermsAndPrivacyListItem noPaddingLeft listMarker="none">
            Members are solely responsible for determining, reporting, and
            paying any applicable taxes associated with their sales on the
            platform.
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyInsideListWrapper>
            <TermsAndPrivacyListItem listMarker="disc">
              <span className="bold">U.S. Tax Compliance:</span>
              {' '}
              For U.S.-based
              Members earning over
              <span className="bold">$600 USD</span>
              in sales, Stripe will issue
              {' '}
              <span className="bold">Form 1099-K</span>
              {' '}
              on behalf of Delivrable
              in compliance with IRS regulations.
            </TermsAndPrivacyListItem>
            <TermsAndPrivacyListItem listMarker="disc">
              <span className="bold">VAT/GST:</span>
              {' '}
              Delivrable does
              {' '}
              <span className="bold">not</span>
              {' '}
              collect, remit, or report
              {' '}
              <span className="bold">
                VAT, GST, or other international sales taxes.
              </span>
              {' '}
              Members must determine their own tax obligations based on their
              jurisdiction.
            </TermsAndPrivacyListItem>
          </TermsAndPrivacyInsideListWrapper>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        Right to Modify Fees & Membership Terms
        <TermsAndPrivacyInsideListWrapper style={{ paddingLeft: 0 }}>
          <TermsAndPrivacyListItem noPaddingLeft listMarker="none">
            Delivrable reserves the right to modify service fees, membership
            pricing, and payment structures at any time. Members will receive
            reasonable prior notice of any changes.
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem noPaddingLeft listMarker="none">
            If a Member does not agree with the revised terms, they may
            terminate their membership before the new fees take effect.
            Continued use of the platform after changes are implemented will
            constitute acceptance of the updated terms.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
    </TermsAndPrivacyNumberedList>
  );
};
