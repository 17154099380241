import React, { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { LandingNumberedLi } from './LandingNumberedLi';
import { TermsAndPrivacyNumberedListWrapper, TermsAndPrivacyListTitle } from './styled';

type NumberedListProps = {
  children: ReactNode;
  listTitle: string;
  startWith: number;
  pointsNameForTrans: string;
};

export const TermsAndPrivacyNumberedList: React.FC<NumberedListProps> = ({
  children,
  listTitle,
  startWith,
  pointsNameForTrans,
}) => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <TermsAndPrivacyNumberedListWrapper start={startWith} noPaddingLeft>
      <LandingNumberedLi isTitle>
        <TermsAndPrivacyListTitle>
          {listTitle}
        </TermsAndPrivacyListTitle>
      </LandingNumberedLi>
      <ol style={{ paddingLeft: '0px' }} start={1}>
        {/* <Trans t={t} i18nKey={`terms.${pointsNameForTrans}`}> */}
        {children}
        {/* </Trans> */}
      </ol>
    </TermsAndPrivacyNumberedListWrapper>
  );
};
