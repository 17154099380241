import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ClientGalleryFile } from '../../../../shared/types';
import { DownloadToastQueue } from '../../types/types';

type DownloadingToastState = {
  isOpen: boolean;
  queue: DownloadToastQueue;
  queueLength: number;
};

const initialState: DownloadingToastState = {
  isOpen: false,
  queue: {},
  queueLength: 0,
};

const downloadingToastSlice = createSlice({
  name: 'downloadingToast',
  initialState,
  reducers: {
    addItemToToastQueue(
      state,
      action: PayloadAction<{
        files: ClientGalleryFile[];
        sectionName: string;
        sectionId: string;
        instantlyDownloadable?: boolean;
      }>,
    ) {
      const {
        files, sectionName, sectionId, instantlyDownloadable = false,
      } = action.payload;
      if (!state.isOpen) {
        state.isOpen = true;
      }
      if (!state.queue[sectionId]) {
        state.queue[sectionId] = {
          files,
          sectionName,
          filesNumber: files.length,
          coverFile: files[0],
          instantlyDownloadable,
        };
        state.queueLength += 1;
      } else if (instantlyDownloadable) {
        state.queue[`${sectionId}-${files[0].id}`] = {
          files,
          sectionName,
          filesNumber: files.length,
          coverFile: files[0],
          instantlyDownloadable,
        };
        state.queueLength += 1;
      }
    },
    setToastItemClose(state, action: PayloadAction<{ sectionId: string, fileId?: string }>) {
      const { sectionId, fileId } = action.payload;
      if (state.queue[sectionId]) {
        delete state.queue[sectionId];
        state.queueLength -= 1;
      }
      if (fileId) {
        if (state.queue[`${sectionId}-${fileId}`]) {
          delete state.queue[`${sectionId}-${fileId}`];
          state.queueLength -= 1;
        }
      }
      if (state.queueLength <= 0) {
        state.isOpen = false;
      }
    },
  },
});

export const { addItemToToastQueue, setToastItemClose } = downloadingToastSlice.actions;
export default downloadingToastSlice.reducer;
