import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LandingNumberedLi } from '../LandingNumberedLi';
import {
  TermsAndPrivacyInsideListWrapper,
  TermsAndPrivacyListItem,
} from '../styled';
import { TermsAndPrivacyNumberedList } from '../TermsAndPrivacyNumberedList';

export const RenewalPolicyPoints: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <TermsAndPrivacyNumberedList
      startWith={19}
      listTitle={t('terms.renewalPolicy')}
      pointsNameForTrans="terminationPoints"
    >
      <LandingNumberedLi>
        Automatic Renewal of Subscription
        <TermsAndPrivacyInsideListWrapper style={{ paddingLeft: 0 }}>
          <TermsAndPrivacyListItem noPaddingLeft listMarker="none">
            Your Delivrable subscription is
            {' '}
            <span className="bold">billed on an annual basis</span>
            {' '}
            and will
            automatically renew
            {' '}
            <span className="bold">
              each year on the anniversary of your initial purchase date
            </span>
            {' '}
            unless you cancel your subscription in accordance with these Terms.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        Payment & Renewal Processing
        <TermsAndPrivacyInsideListWrapper>
          <TermsAndPrivacyListItem listMarker="disc">
            Your
            {' '}
            <span className="bold">annual subscription fee</span>
            {' '}
            will be
            {' '}
            <span className="bold">automatically charged</span>
            {' '}
            to the payment
            method on file via
            <span className="bold">Stripe</span>
            {' '}
            on the renewal date.
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            By subscribing, you
            {' '}
            <span className="bold">
              authorize Delivrable and Stripe to process this payment
              automatically
            </span>
            {' '}
            unless you take action to cancel your subscription prior to renewal.
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            If a payment fails due to insufficient funds, expired cards, or any
            other reason, Stripe may attempt to reprocess the payment up to
            {' '}
            <span className="bold">three (3) times</span>
            {' '}
            before the
            subscription is suspended or terminated.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        Subscription Cancellation & Termination
        {' '}
        <TermsAndPrivacyInsideListWrapper>
          <TermsAndPrivacyListItem listMarker="disc">
            You may cancel your annual subscription at any time before the
            renewal date by accessing your
            {' '}
            <span className="bold">Account Settings</span>
            {' '}
            or contacting
            Delivrable Support.
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            <span className="bold">
              Cancellations must be completed at least 24 hours before the
              renewal date
            </span>
            {' '}
            to avoid being charged for the next billing cycle.
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            If you cancel your subscription, you will retain access to
            Delivrable until the end of your
            {' '}
            <span className="bold">current billing period</span>
            , after which
            your account will revert to a
            {' '}
            <span className="bold">
              free plan (if applicable) or be deactivated
            </span>
            .
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            <span className="bold">
              No refunds will be issued for partial use of the subscription
              period.
            </span>
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        Changes to Subscription Pricing
        <TermsAndPrivacyInsideListWrapper>
          <TermsAndPrivacyListItem listMarker="disc">
            Delivrable reserves the right to adjust pricing for its annual
            subscription plans.
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            If a price increase applies to your subscription, you will be
            {' '}
            <span className="bold">notified at least 30 days in advance</span>
            {' '}
            via email and given the opportunity to
            {' '}
            <span className="bold">
              cancel your subscription before the next billing cycle
            </span>
            .
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            If you do not cancel before the renewal date, you agree to the new
            pricing, and the updated subscription fee will be charged on the
            next renewal cycle.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        Payment Disputes & Chargebacks
        <TermsAndPrivacyInsideListWrapper>
          <TermsAndPrivacyListItem listMarker="disc">
            If you dispute an automatic renewal charge
            {' '}
            <span className="bold">
              without first attempting to cancel your subscription
            </span>
            , Delivrable reserves the right to dispute the chargeback and may
            {' '}
            <span className="bold">suspend your access to the platform</span>
            until the issue is resolved.
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            Delivrable is
            {' '}
            <span className="bold">not responsible for fees or penalties</span>
            {' '}
            incurred from failed or disputed payments processed through Stripe.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        No Refunds for Auto-Renewals
        <TermsAndPrivacyInsideListWrapper>
          <TermsAndPrivacyListItem listMarker="disc">
            <span className="bold">
              All subscription payments are final and non-refundable
            </span>
            , including
            {' '}
            <span className="bold">
              renewals that have already been processed
            </span>
            .
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            It is your responsibility to monitor your renewal date and ensure
            you cancel your subscription in a timely manner if you do not wish
            to renew.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        Stripe as the Payment Processor
        <TermsAndPrivacyInsideListWrapper>
          <TermsAndPrivacyListItem listMarker="disc">
            All subscription payments, renewals, and billing-related
            transactions are processed via
            {' '}
            <span className="bold">Stripe</span>
            .
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            By subscribing to Delivrable, you also
            {' '}
            <span className="bold">
              agree to Stripe’s Terms of Service and Payment Processing Policies
            </span>
            , which can be found at
            {' '}
            <Link target="_blank" to="https://www.stripe.com">
              stripe.com
            </Link>
            .
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
    </TermsAndPrivacyNumberedList>
  );
};
