import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled as MUIStyled } from '@mui/material';
import styled from '@emotion/styled';
import * as variables from '../../../constants/styles';

export const CustomMenu = MUIStyled(Menu)(() => ({
  '& .MuiPaper-root': {
    display: 'flex',
    flexDirection: 'column',
    background: '#f8f8f8',
  },
  '& .MuiList-padding': {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const DotsMenuButton = styled.button`
  font-size: 16px;
  width: 100%;
  padding: 6px 16px 6px;
  text-align: start;
`;

export const DotsIconElement = styled.ul`
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  width: fit-content;
  transition: 0.2s ease-in-out;
  padding: 0px;
  margin: 0px;

  & li {
    background: none repeat scroll 0 0 ${variables.colorBtnSecondary};
    height: 5px;
    width: 5px;
    line-height: 0;
    list-style: none outside none;
    vertical-align: top;
    border-radius: 50%;
    pointer-events: none;
    transition: 0.2s ease-in-out;
    padding: 0px;
    margin: 0px 9px;

    &:not(:first-of-type) {
      margin-top: 3px;
    }
  }
`;

export const DotsIcon: React.FC = () => (
  <DotsIconElement>
    <li />
    <li />
    <li />
  </DotsIconElement>
);

type DotsMenu = {
  options: React.ReactElement[];
  closeOnClick?: boolean;
  close?: boolean;
};

export const DotsMenu: React.FC<DotsMenu> = ({ options, closeOnClick = true, close }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (close && !closeOnClick) {
      setTimeout(() => setAnchorEl(null), 800);
    }
  }, [close, closeOnClick]);

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        style={{
          borderRadius: '50%',
          padding: '10px',
        }}
      >
        <DotsIcon />
      </IconButton>
      <CustomMenu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            onClick={closeOnClick ? handleClose : undefined}
          >
            {option}
          </MenuItem>
        ))}
      </CustomMenu>
    </div>
  );
};
