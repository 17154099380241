/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import GridViewIcon from '@mui/icons-material/GridView';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import {
  Box,
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { SimpleTooltip } from '../../../../../../../../../shared/components/HelpTip/SimpleTooltip';
import {
  DownloadIcon,
  HeartIcon,
} from '../../../../../../../../../shared/icons';
import {
  FileCardFileWrapper,
  LikesOrDownloadsFileWrapper,
  LikesOrDownloadsIconWrapper,
  LikesOrDownloadsWrapper,
} from './styled';
import { MediumBlackText, SimpleBlackText, TextBlockLimitedWidth } from '../../../../../../../../../shared/styles';
import { getFileInContainer } from '../../../../../../../../../shared/utils/createGallery';
import { isIdea } from '../../../../../../../../../shared/utils';
import {
  ClientOrCreatorGalleryFile,
  CreatorGalleryFile,
  GalleryIdea,
} from '../../../../../../../../../shared/types/commonGallery';
import { FavouritesCartResItem } from '../../../../../../../../../shared/types';
import { getFileUrls } from '../../../../../../../../../shared/utils/gallery/setFileUrls';
import {
  colorPink,
  textGrey,
} from '../../../../../../../../../constants/styles';
import {
  tableContainerStyle,
  StyledTable,
  StyledTableCell,
} from '../../../../../../../../../shared/components/StyledTable/StyledTable';

type LikesOrDownloadsProps = {
  likesOrDownloads: FavouritesCartResItem[];
  type: 'like' | 'download';
};

export const FileCard = ({ file }: { file: ClientOrCreatorGalleryFile }) => (
  <Stack flexDirection="row" alignItems="center">
    <FileCardFileWrapper>
      {getFileInContainer({
        file,
      })}
    </FileCardFileWrapper>
    <Box>
      <MediumBlackText style={{ display: 'inline-flex' }}>
        <TextBlockLimitedWidth
          style={{ display: 'inline-block' }}
          maxWidth={400}
        >
          {file.name}
        </TextBlockLimitedWidth>
      </MediumBlackText>
    </Box>
  </Stack>
);

export const LikesOrDownloadsListItem = ({
  email,
  file,
}: {
  email: string;
  file: ClientOrCreatorGalleryFile;
}) => (
  <TableRow key={file.id}>
    <TableCell
      width="470px"
      sx={{
        minWidth: '100px',
        maxWidth: '470px',
        borderBottom: '0px',
        paddingBottom: '8px',
        paddingTop: '8px',
        position: 'relative',
        paddingRight: '40px',
        paddingLeft: '0px',
      }}
      align="left"
    >
      <FileCard file={file} />
    </TableCell>
    <TableCell
      sx={{ borderBottom: '0px', paddingBottom: '8px', paddingTop: '8px' }}
      align="left"
    >
      <TextBlockLimitedWidth
        style={{ display: 'inline-block' }}
        maxWidth={200}
      >
        {email}
      </TextBlockLimitedWidth>
    </TableCell>
  </TableRow>
);

export const LikesOrDownloadsListView = ({ list }: { list: FavouritesCartResItem[] }) => (
  <TableContainer
    sx={{
      maxHeight: '250px',
      ...tableContainerStyle,
    }}
  >
    <StyledTable stickyHeader>
      <TableHead>
        <TableRow>
          <StyledTableCell
            sx={{
              whiteSpace: 'pre',
              paddingLeft: '0px',
              paddingTop: '0px',
            }}
            align="left"
          >
            File name
          </StyledTableCell>
          <StyledTableCell sx={{ paddingTop: '0px' }} align="left">
            Email
          </StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {list?.map((item) => {
          const fileWithUrls = getFileUrls(
            isIdea(item)
              ? ((item as GalleryIdea).cover as CreatorGalleryFile)
              : (item as CreatorGalleryFile),
          );
          return (
            <LikesOrDownloadsListItem key={item.id} file={fileWithUrls} email={item.email || 'Unknown'} />
          );
        })}
      </TableBody>
    </StyledTable>
  </TableContainer>
);

export const LikesOrDownloadsTab: React.FC<LikesOrDownloadsProps> = ({
  likesOrDownloads,
  type,
}) => {
  const { t } = useTranslation('gallery');
  const [view, setView] = useState<'list' | 'grid'>('grid');

  if (!likesOrDownloads?.length) {
    if (type === 'download') {
      return <SimpleBlackText>{t('noDownloads')}</SimpleBlackText>;
    }
    return <SimpleBlackText>{t('noLikes')}</SimpleBlackText>;
  }

  return (
    <>
      {view === 'grid' ? (
        <LikesOrDownloadsWrapper>
          {likesOrDownloads.map((item) => {
            const fileWithUrls = getFileUrls(
              isIdea(item)
                ? ((item as GalleryIdea).cover as CreatorGalleryFile)
                : (item as CreatorGalleryFile),
            );
            return (
              <LikesOrDownloadsFileWrapper key={item.id}>
                {getFileInContainer({
                  file: fileWithUrls,
                })}
                <LikesOrDownloadsIconWrapper
                  style={{ background: 'transparent' }}
                >
                  {type === 'download' ? <DownloadIcon /> : <HeartIcon />}
                  <SimpleTooltip
                    text={item.email || 'Anonym'}
                    relativeWidth={20}
                  />
                </LikesOrDownloadsIconWrapper>
              </LikesOrDownloadsFileWrapper>
            );
          })}
        </LikesOrDownloadsWrapper>
      ) : (<LikesOrDownloadsListView list={likesOrDownloads} />)}
      <Box
        mr="30px"
        mt="-30px"
        display="flex"
        flexDirection="row"
        alignItems="center"
        position="absolute"
        right="30px"
      >
        <IconButton
          style={{ padding: '4px', borderRadius: '50%' }}
          onClick={() => setView('list')}
        >
          <FormatListBulletedIcon
            fontSize="medium"
            sx={{ color: view === 'list' ? colorPink : textGrey }}
          />
        </IconButton>
        <IconButton
          style={{ padding: '4px', borderRadius: '50%' }}
          onClick={() => setView('grid')}
        >
          <GridViewIcon
            fontSize="medium"
            sx={{ color: view === 'grid' ? colorPink : textGrey }}
          />
        </IconButton>
      </Box>
    </>
  );
};
