import { GalleryFileBase, GalleryFileSettings, GallerySectionFields } from '../../../shared/types';
import { ClientOrderFilesItem } from '../../../shared/types/payment';

export type SalesGet = {
  amount: number;
  amountPerWeek: number;
};

export type AllSalesGet = {
  prev1: number[];
  prev7: number[];
  prev31: number[];
  countPrev31: number;
  totalPrev31: number;
  netRevenueAllTime: number;
};

export enum OrderStatus {
  canceled = 'canceled',
  confirmed = 'confirmed',
  pending = 'pending',
}

export type OrderedFile = {
  sectionName: string;
  fileName: string;
  downloaded: boolean;
  disableDownloads: boolean;
  withinFreeFileLimit: boolean;
  instantlyDownloadable: boolean;
  price: number;
}

export type Order = {
  id: string;
  email: string;
  status: OrderStatus;
  createOrderTimestamp: string;
  amount: number;
  files: (GalleryFileBase & GalleryFileSettings & GallerySectionFields)[];
  limitedFreeFiles: (GalleryFileBase & GalleryFileSettings & GallerySectionFields)[];
  name?: string;
}

export type GalleryIdName = {
  id: string;
  name: string;
};

export type OrderResponse = {
  id: string;
  email: string;
  status: OrderStatus;
  createOrderTimestamp: string;
  amount: number;
  limitedFreeFiles: ClientOrderFilesItem[];
  files: ClientOrderFilesItem[];
  galleryId: string | GalleryIdName;
}

export type GetOrderByGalleryIdDto = {
  [id: string]: { name: string, orders: Order[] }
}

export type GetOrderByCreatorIdDto = {
  [id: string]: { name: string, orders: Order[] }
}

export type GetOrdersResponse = {
  data: OrderResponse[];
  count: number;
}
