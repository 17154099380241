import { DialogContent } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { AlertDialog } from '../../../../../../../shared/components/CustomAlert/AlertDialog';
import {
  getAlertStyles,
  CustomTitleDialog,
  CustomDialogContentText,
  CustomDialogActions,
} from '../../../../../../../shared/components/CustomAlert/styled';
import { LoadingForRequest } from '../../../../../../../shared/components/LoadingForRequest/LoadingForRequest';
import { CloseIcon } from '../../../../../../../shared/icons';
import {
  PrimaryWhiteButton,
  SimplePinkText,
} from '../../../../../../../shared/styles';
import { useErrors } from '../../../../../hooks/useErrors';
import { CloseButton } from '../../ActiveGalleries/Dashboard/DashboardHeader/styled';
import { useSaveGallery } from '../hooks/useSaveGallery';
import { ButtonsWrapper } from '../styled';
import useConfirm from './useConfirm';
import { useDeleteGallery } from '../hooks/useDeleteGallery';
import { CreatorGalleryForm } from '../../../../../../../shared/types/creatorGallery';
import { setSuccessDeleteFalse } from '../../../../../redux/gallery/allGaleries/galleryCreatorSlice';
import { setSuccessSaveFalse, setSuccessUpdateFalse } from '../../../../../redux/gallery/singleGallery/singleGalleryCreatorSlice';
import { useCreatorAppDispatch } from '../../../../../../../shared/hooks/useTypedSelector';

interface Props {
  when?: boolean | undefined;
  navigate: (path: string) => void;
  id: string;
}

export const RouteLeavingGuard: React.FC<Props> = ({ when, navigate, id }) => {
  const { t } = useTranslation(['basicComponents', 'createNewGallery']);
  const {
    handleUpdateGallery, isLoading, isSuccess, errorAlert,
  } = useSaveGallery();
  const {
    error,
    deleteGallery,
    isLoading: loadingDelete,
    isSuccess: isSuccessDelete,
  } = useDeleteGallery();
  const { state } = useLocation();
  const errorAlertDelete = useErrors(error);
  const { promise, handleCancel, handleConfirm } = useConfirm();
  const { values, initialValues } = useFormikContext<CreatorGalleryForm>();
  const dispatch = useCreatorAppDispatch();

  const isEdit = state?.status === 'edited';

  const handleDelete = () => {
    deleteGallery(id);
  };

  const handleDiscardChanges = () => {
    const status = isEdit ? (initialValues.status || 'draft') : 'draft';
    handleUpdateGallery(initialValues, status);
  };

  const handleSave = () => {
    const status = isEdit ? (initialValues.status || 'draft') : 'draft';
    handleUpdateGallery(values, status);
  };

  useEffect(() => {
    if (isSuccess || isSuccessDelete) {
      setTimeout(() => {
        dispatch(setSuccessSaveFalse());
        dispatch(setSuccessUpdateFalse());
        dispatch(setSuccessDeleteFalse());
        handleConfirm();
      }, 570);
    }
  }, [isSuccess, isSuccessDelete]);

  return (
    <>
      <div>
        <AlertDialog
          open={promise !== null}
          onClose={handleCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{ style: getAlertStyles('confirmation') }}
        >
          <CloseButton
            type="button"
            onClick={handleCancel}
            style={{ right: 10, top: 20 }}
          >
            <CloseIcon />
          </CloseButton>
          <CustomTitleDialog confirmation id="alert-dialog-title">
            {t('basicComponents:closeWithoutSaving')}
          </CustomTitleDialog>
          <DialogContent>
            <CustomDialogContentText id="alert-dialog-description">
              {isEdit
                ? t('createNewGallery:saveChangesOrDiscard')
                : t('createNewGallery:saveOrDelete')}
            </CustomDialogContentText>
          </DialogContent>
          <CustomDialogActions>
            <ButtonsWrapper style={{ marginTop: '10px', marginBottom: '10px' }}>
              <SimplePinkText>
                <PrimaryWhiteButton
                  type="button"
                  onClick={handleSave}
                  color="pink"
                >
                  {isEdit
                    ? t('createNewGallery:saveChanges')
                    : t('basicComponents:buttons.saveToDrafts')}
                </PrimaryWhiteButton>
              </SimplePinkText>
              <PrimaryWhiteButton
                type="button"
                onClick={isEdit ? handleDiscardChanges : handleDelete}
                style={{ marginLeft: '20px' }}
              >
                {isEdit
                  ? t('createNewGallery:discard')
                  : t('basicComponents:buttons.delete')}
              </PrimaryWhiteButton>
            </ButtonsWrapper>
          </CustomDialogActions>
        </AlertDialog>
      </div>
      {(isLoading || loadingDelete || isSuccess || isSuccessDelete) && (
        <LoadingForRequest
          text={t('basicComponents:changesSaved')}
          isLoading={isLoading || loadingDelete}
          isSuccess={isSuccess || isSuccessDelete}
          goToMainPage={false}
        />
      )}
      {errorAlert}
      {errorAlertDelete}
    </>
  );
};
