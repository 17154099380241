import { Box } from '@mui/material';
import React from 'react';
import { PinkLoader } from '../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { TickIcon, CartIcon, DownloadIcon } from '../../../shared/icons';
import { ClientGalleryGet } from '../../../shared/types/clientGallery';
import { CreatorGalleryGet } from '../../../shared/types/creatorGallery';

export const getSectionId = (
  gallery: ClientGalleryGet | CreatorGalleryGet | null,
  fileId: string,
): string => {
  if (!gallery) {
    return '';
  }
  const res = gallery.sections.find((section) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    [...section.files, ...section.ideas].find((file) => file.id === fileId));
  return res?.id || '';
};

export const getIcon = (isLoading: boolean, isSuccess: boolean, hasToPay: boolean) => {
  if (isLoading) return <PinkLoader sx={{ marginRight: '6px' }} size="sm" />;
  if (isSuccess) {
    return (
      <Box
        sx={{ height: '28px', width: '28px', mr: '6px' }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <TickIcon isGradient={false} sx={{ height: '16px', width: '26px' }} />
      </Box>
    );
  }
  if (hasToPay) {
    return (
      <CartIcon
        sx={{ height: '28px', width: '28px', mr: '6px' }}
        height={24}
        width={24}
      />
    );
  }
  return (
    <DownloadIcon
      sx={{ height: '28px', width: '28px', mr: '6px' }}
      height={24}
      width={24}
    />
  );
};

export const getText = (
  isSuccess: boolean,
  hasToPay: boolean,
  isDownloading: boolean,
) => {
  if (hasToPay) {
    if (isSuccess) return 'Added to cart';
    return 'Add all to cart';
  }
  return 'Download all';
};
