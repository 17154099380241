import React from 'react';
import { useTranslation } from 'react-i18next';
import { LandingNumberedLi } from '../LandingNumberedLi';
import {
  TermsAndPrivacyInsideListWrapper,
  TermsAndPrivacyListItem,
} from '../styled';
import { TermsAndPrivacyNumberedList } from '../TermsAndPrivacyNumberedList';

export const LimitationsPoints: React.FC = () => {
  const { t } = useTranslation('termsAndPrivacy');

  return (
    <TermsAndPrivacyNumberedList
      startWith={17}
      listTitle={t('terms.limitations')}
      pointsNameForTrans="limitationsPoints"
    >
      <LandingNumberedLi>
        To the maximum extent permitted by law:
        <TermsAndPrivacyInsideListWrapper>
          <TermsAndPrivacyListItem listMarker="disc">
            No Indirect or Consequential Damages: Neither Party shall be liable
            for any indirect, incidental, special, consequential, or exemplary
            damages, including but not limited to lost profits, lost revenue,
            business interruption, loss of data, goodwill, or anticipated
            savings, even if the Party has been advised of the possibility of
            such damages.
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            Proportional Liability Reduction: Each Party’s liability under these
            Terms shall be reduced proportionately to the extent that the
            Liability was caused or contributed to by the acts, omissions,
            negligence, or misconduct of the other Party or its personnel,
            including any failure by that Party to mitigate its losses.
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem listMarker="disc">
            Member Liability Cap: If you are a Member, our aggregate liability
            for any Liability arising from or in connection with these Terms
            shall be excluded to the fullest extent permitted by law and, where
            not excluded, shall be limited to the last invoice paid by you to
            Delivrable within the 12 months preceding the event giving rise to
            the claim.
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem
            listMarker="disc"
            style={{ marginBottom: '0px' }}
          >
            Client Liability Cap: If you are a Client, our aggregate liability
            arising from or in connection with these Terms shall be limited to
            either:
            <TermsAndPrivacyInsideListWrapper style={{ paddingLeft: 0 }}>
              <TermsAndPrivacyInsideListWrapper>
                <TermsAndPrivacyListItem>
                  resupplying the Delivrable Services to you; or
                </TermsAndPrivacyListItem>
                <TermsAndPrivacyListItem>
                  in our sole discretion, paying you an amount not exceeding
                  $1.00 USD.
                </TermsAndPrivacyListItem>
              </TermsAndPrivacyInsideListWrapper>
            </TermsAndPrivacyInsideListWrapper>
          </TermsAndPrivacyListItem>
          <TermsAndPrivacyListItem
            listMarker="disc"
            style={{ marginBottom: '0px' }}
          >
            No Liability for Data Loss or Security Breaches: Delivrable does not
            warrant that the Platform will be uninterrupted, error-free, or
            completely secure. You acknowledge that file storage and digital
            transmission involve inherent risks, and Delivrable shall not be
            liable for any data loss, corruption, unauthorized access, or
            security breaches affecting your content.
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
      <LandingNumberedLi>
        Survival of This Clause
        <TermsAndPrivacyInsideListWrapper style={{ paddingLeft: 0 }}>
          <TermsAndPrivacyListItem noPaddingLeft listMarker="none">
            These limitations of liability shall survive the termination,
            expiration, or suspension of these Terms and continue to apply even
            if you discontinue use of the Platform.
            {' '}
          </TermsAndPrivacyListItem>
        </TermsAndPrivacyInsideListWrapper>
      </LandingNumberedLi>
    </TermsAndPrivacyNumberedList>
  );
};
