import { useState } from 'react';
import {
  useClientAppDispatch,
  useTypedSelectorClient,
} from '../../../shared/hooks/useTypedSelector';
import {
  ClientGalleryFile,
  FileBySection,
  FileBySectionPut,
  RequestStatuses,
} from '../../../shared/types';
import { fetchAddToCart } from '../redux/interactions/interactionsClientThunk';
import { getAllFilesFromCart } from '../utils/getAllFilesFromCart';
import {
  selectClientCart,
  selectClientLimitedFreeCartFiles,
} from '../redux/interactions/interactionsClientSelectors';

type ReturnObj = {
  requestStatus: RequestStatuses;
  handleAddToCart: (files: ClientGalleryFile[]) => void;
};

export const useAddFilesToCart = (): ReturnObj => {
  const dispatch = useClientAppDispatch();

  const [addRequestStatus, setAddRequestStatus] = useState<RequestStatuses>('idle');
  const gallery = useTypedSelectorClient((state) => state.signIn?.gallery);

  // eslint-disable-next-line max-len
  const cart = useTypedSelectorClient((state) => selectClientCart(state?.interactionsClient?.interaction?.cartFiles));
  const freeFiles = useTypedSelectorClient((state) => selectClientLimitedFreeCartFiles(
    state.interactionsClient?.interaction?.limitedFreeCartFiles,
  ));

  const handleAddToCart = async (files: ClientGalleryFile[]) => {
    setAddRequestStatus('pending');

    const allCartFiles = getAllFilesFromCart([
      ...cart.sections,
      ...freeFiles.sections,
    ]);
    if (addRequestStatus === 'idle') {
      const sectionIdsAndRemainFreeFiles: {
        [key: string]: { remainFreeFiles: number; freeFileLimit: number };
      } = {};
      gallery?.sections?.forEach((section) => {
        if (sectionIdsAndRemainFreeFiles[section.id] === undefined) {
          sectionIdsAndRemainFreeFiles[section.id] = {
            freeFileLimit: 0,
            remainFreeFiles: 0,
          };
        }
        sectionIdsAndRemainFreeFiles[section.id].freeFileLimit = section.freeFileLimit || 0;
        sectionIdsAndRemainFreeFiles[section.id].remainFreeFiles = section.freeFilesRemaining || 0;
      });

      try {
        const limitedFreeCartFiles: FileBySectionPut[] = [];
        const updateLimitedFreeCartFiles: FileBySection[] = [];
        const cartFiles: FileBySectionPut[] = [];
        const updateCartFiles: FileBySection[] = [];

        files.forEach((file) => {
          const fileInCart = allCartFiles.find((item) => item.id === file.id);
          if (!fileInCart || !allCartFiles.length) {
            if (
              sectionIdsAndRemainFreeFiles[file.sectionId || '']
                .remainFreeFiles
              && file.price
            ) {
              sectionIdsAndRemainFreeFiles[
                file.sectionId || ''
              ].remainFreeFiles -= 1;
              limitedFreeCartFiles.push({
                sectionId: file.sectionId || '',
                file: file.id,
              });
              updateLimitedFreeCartFiles.push({
                sectionId: file.sectionId || '',
                file: { ...file, price: '0' },
              });
            } else {
              cartFiles.push({
                sectionId: file.sectionId || '',
                file: file.id,
              });
              updateCartFiles.push({
                sectionId: file.sectionId || '',
                file,
              });
            }
          }
        });
        await dispatch(
          fetchAddToCart({
            interactions: {
              cartFiles,
              limitedFreeCartFiles: limitedFreeCartFiles.length
                ? limitedFreeCartFiles
                : [],
            },
            updateStateData: {
              cartFiles: updateCartFiles,
              limitedFreeCartFiles: updateLimitedFreeCartFiles,
            },
          }),
        );
        setAddRequestStatus('succeeded');
      } catch (err) {
        console.error('Failed to save the post: ', err);
        setAddRequestStatus('error');
      } finally {
        setTimeout(() => {
          setAddRequestStatus('idle');
        }, 2000);
      }
    }
  };

  return {
    requestStatus: addRequestStatus,
    handleAddToCart,
  };
};
